import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  Button,
  Input,
  Row,
  Col,
  Collapse,
  message,
  Tooltip,
} from 'antd'
import {
  CheckOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'
import { RootState } from '@/states/reducers'
import { setCategoryFormModal } from '@/states/actions/modals.actions'
import { COMPONENTS_QUICK_BUTTONS_KEY, LAYOUT_MODAL_WIDTH } from '@/configs'
import { setTmpModel } from '@/states/actions/models.actions'
import { AlertStatus } from '@/components/common/common.alert'
import {
  ComponentFieldType,
  ComponentOptionInterface,
  ComponentTypeInterface,
  LanguageMap,
} from '@/types'
import { availableComponents, LAYOUT_COMPONENT_TYPE_WIDTH } from '@/configs'
import { ComponentsTypeItem, ComponentsOption } from '@/components/components'
import { setDefaultComponentOptionValue } from '@/utils/helpers'
import { useAppDispatch } from '@/states/store'
import {
  createCategoryGroup,
  createCategorySelector,
  updateCategorySelector,
  getCategoryGroups,
  updateCategoryGroup,
  deleteCategoryGroup,
} from '@/states/actions/categories.actions'

const initialComponentFormValues = {
  name: '',
  selector: {
    KO: '',
    EN: '',
    JP: '',
    CN: '',
  },
}

const CATEGORY_TYPE = 'CATEGORY'

export const CategoriesFormModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const [, updateState] = React.useState<{} | undefined>()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,

      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { categoryFormModal, categoryInfo, parentComponentInfo } = modalsState
  const { currentProject, tmpModel, flattenComponentList, categoriesList } =
    projectsState

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [typeObj, setTypeObj] = useState<
    ComponentTypeInterface | null | undefined
  >(availableComponents.find((c) => c.type === CATEGORY_TYPE))
  const [options, setOptions] = useState<ComponentOptionInterface>({})

  // Effect
  useEffect(() => {
    if (categoryFormModal) {
      setTimeout(() => {
        document.getElementById('componentFormName')?.focus()
      })

      if (!categoryInfo) {
        formikCategoryForm.validateForm()
      }
    } else {
      resetForm()
    }
  }, [categoryFormModal])

  useEffect(() => {
    if (categoryInfo && currentProject) {
      setTypeObj(availableComponents.find((c) => c.type === CATEGORY_TYPE))
      formikCategoryForm.setFieldValue(
        'name',
        categoryInfo.languageMap[currentProject.defaultLang]
      )

      // 카테고리 선택지
      if (categoryInfo.selectorList) {
        currentProject.languageList.forEach((curLang) => {
          let categoryStr = ''

          categoryInfo.selectorList?.forEach((lang, lIdx) => {
            if (lIdx) categoryStr += '\n'
            categoryStr += lang.languageMap[curLang]
          })

          formikCategoryForm.setFieldValue('selector.' + curLang, categoryStr)
        })
      }
    }
  }, [categoryInfo])

  // Validation
  const validationComponentFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    // devKey: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikCategoryForm = useFormik({
    initialValues: initialComponentFormValues,
    validationSchema: validationComponentFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!currentProject) return false

      // 언어별 값 선택
      const languageMap = {}
      currentProject.languageList.forEach((lang) => {
        languageMap[lang] = values.name.normalize('NFC').replace('\b', '')
      })

      const req = {
        order: 0,
        languageMap,
        selectorList: [] as { languageMap: LanguageMap }[],
      }

      // 카테고리 선택
      let isCategoryValid = true
      const oldSelectorLength = categoryInfo?.selectorList?.length
      const selectorList: {
        languageMap: LanguageMap
        id?: number
        order?: number
        previousVersionId?: number
      }[] = []
      let initCategoryLength = values.selector[
        currentProject.defaultLang
      ].trim()
        ? values.selector[currentProject.defaultLang].trim().split('\n').length
        : 0
      currentProject.languageList.forEach((lang, langIdx) => {
        const curCateArr = values.selector[lang].trim()
          ? values.selector[lang].trim().split('\n')
          : []

        if (new Set(curCateArr).size !== curCateArr.length) {
          message.warning(t('categoryValueDuplicate'))
          isCategoryValid = false
          return false
        }

        if (initCategoryLength !== curCateArr.length) {
          message.warning(t('categoryValueLength'))
          isCategoryValid = false
          return false
        }

        // 언어별 selector 넣기
        curCateArr.forEach((cate, cateIdx) => {
          if (langIdx === 0) {
            const languageMapValue = {
              languageMap: {},
            } as { languageMap: LanguageMap }
            languageMapValue.languageMap[lang] = cate

            selectorList.push(languageMapValue)
          } else {
            selectorList[cateIdx].languageMap[lang] = cate
          }

          selectorList[cateIdx].order = cateIdx + 1

          if (
            categoryInfo &&
            categoryInfo.selectorList &&
            oldSelectorLength &&
            oldSelectorLength > cateIdx
          ) {
            selectorList[cateIdx].previousVersionId =
              categoryInfo?.selectorList[cateIdx].id
            selectorList[cateIdx].id = categoryInfo?.selectorList[cateIdx].id
          }
        })
      })

      if (!isCategoryValid) return false

      req.selectorList = selectorList

      // 추가
      if (!categoryInfo) {
        req.order = categoriesList.length

        createCategoryGroup(currentProject.uid, req)
          .then(async (res) => {
            await createCategorySelector(currentProject?.uid, res.data, req)
            await dispatch(getCategoryGroups(currentProject.uid))
          })
          .catch((e) => {})
          .then(() => {
            dispatch(setCategoryFormModal(false))
          })
      }
      // 수정
      else {
        req.order = categoryInfo.order

        updateCategoryGroup(currentProject.uid, categoryInfo.id, req)
          .then(async (res) => {
            await updateCategorySelector(
              currentProject?.uid,
              categoryInfo.id,
              req
            )
            await dispatch(getCategoryGroups(currentProject.uid))
          })
          .catch((e) => {})
          .then(() => {
            dispatch(setCategoryFormModal(false))
          })
      }
    },
  })

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikCategoryForm.resetForm()
    setLoading(false)
    setTypeObj(null)
    setOptions({})
  }

  /**
   * 카테고리 삭제
   */
  const onDeleteCategory = () => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteCategoryTitle', {
        category: categoryInfo?.languageMap.KO,
      }),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteCategoryDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        deleteCategoryGroup(currentProject?.uid, categoryInfo?.id).then(
          (res) => {
            dispatch(getCategoryGroups(currentProject?.uid))
            dispatch(setCategoryFormModal(false))
            message.success(t('deleteSuccess'))
          }
        )
      },
      onCancel() {},
    })
  }

  return currentProject ? (
    <>
      {/* 카테고리 모달: 시작 */}
      <Modal
        width={LAYOUT_MODAL_WIDTH}
        closeIcon={<CloseCircleOutlined title={t('close')} />}
        open={categoryFormModal}
        maskClosable={false}
        onCancel={() => dispatch(setCategoryFormModal(false))}
        title={
          <div className="flex items-center space-x-2">
            <span>{categoryInfo ? t('editCategory') : t('addCategory')}</span>
          </div>
        }
        footer={[
          <div key={'footer'} className={'flex justify-between items-center'}>
            <div>
              <Button
                type="primary"
                ghost
                onClick={() => dispatch(setCategoryFormModal(false))}>
                {t('cancel')}
              </Button>
              {categoryInfo ? (
                <Button
                  type="primary"
                  danger
                  onClick={() => onDeleteCategory()}>
                  {t('deleteCategory')}
                </Button>
              ) : (
                <></>
              )}
            </div>
            <div>
              <Button
                type={'primary'}
                icon={<CheckOutlined />}
                onClick={() => formikCategoryForm.submitForm()}
                disabled={loading}
                loading={loading}>
                {categoryInfo ? t('update') : t('addCategory')}
              </Button>
            </div>
          </div>,
        ]}>
        <>
          <form onSubmit={formikCategoryForm.handleSubmit} method="POST">
            <AlertStatus
              status={formikCategoryForm.status}
              onClick={() => formikCategoryForm.setStatus(null)}></AlertStatus>
            <div className={'space-y-6'}>
              {/* 카테고리 폼 선택: 시작 */}
              <Row gutter={24}>
                <Col span={24}>
                  <div className="block">
                    <label htmlFor="categoryFormName" className="">
                      <div className={'mb-2'}>
                        {t('categoryName')}{' '}
                        <span className="text-red-500">*</span>
                      </div>
                      <Input
                        id={'categoryFormName'}
                        name="name"
                        onChange={formikCategoryForm.handleChange}
                        value={formikCategoryForm.values.name}
                      />
                    </label>
                    {formikCategoryForm.touched.name &&
                    formikCategoryForm.errors.name ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikCategoryForm.errors.name}
                      </p>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {/* 카테고리 선택: 시작 */}
              <div>
                <div className="mb-2">{t('categoryValue')}</div>
                <Collapse>
                  {currentProject.languageList.map((lang) => (
                    <Collapse.Panel
                      header={
                        t('lang.' + lang.toLowerCase()) +
                        '(' +
                        (formikCategoryForm.values.selector[lang]
                          ? formikCategoryForm.values.selector[lang]
                              .trim()
                              .split('\n').length
                          : 0) +
                        ')'
                      }
                      key={lang}>
                      <Input.TextArea
                        size="small"
                        name={`selector.${lang}`}
                        rows={3}
                        onChange={formikCategoryForm.handleChange}
                        value={formikCategoryForm.values.selector[lang]}
                      />
                    </Collapse.Panel>
                  ))}
                </Collapse>
                <p className="text-xs text-gray-500 mt-1">
                  {t('categoryValueDesc')}
                </p>
              </div>
              {/* 카테고리 선택: 시작 */}
            </div>
          </form>
        </>
      </Modal>
      {/* 카테고리 모달: 끝 */}
    </>
  ) : (
    <></>
  )
}
