import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Button, Spin, message, Pagination, Modal, Tooltip } from 'antd'
import {
  PlusOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
  ArrowsAltOutlined,
  InfoCircleOutlined,
  CloudUploadOutlined,
  CloudSyncOutlined,
} from '@ant-design/icons'
import { LayoutProjects } from '@/components/layout'
import {
  CellInterface,
  ProjectModelUsageInterface,
  UserInterface,
} from '@/types'
import { RootState } from '@/states/reducers'
import {
  ContentsListExcel,
  ContentsLanguage,
  ContentsTools,
} from '@/components/contents'
import {
  LAYOUT_CHECKBOX_CELL_WIDTH,
  LAYOUT_OKAY_COLOR,
  LAYOUT_SUCCESS_COLOR,
  LAYOUT_WARNING_COLOR,
} from '@/configs'
import { setContentsFormModal } from '@/states/actions/modals.actions'
import {
  createContents,
  deleteMultiContents,
  getContentsItem,
  setCheckedContentsList,
  setContentsCellItem,
  addContentsList,
  deleteContentsList,
} from '@/states/actions/contents.actions'
import { ModelsFormModal } from '@/components/models'
import { getProjectUsage } from '@/states/actions/projects.actions'
import { getModels, validateModel } from '@/states/actions/models.actions'
import moment from 'moment-timezone'
import { useAppDispatch } from '@/states/store'

let scrollTimer

const ProjectsContentsByModel = (props) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  // Params
  const { projectUid, modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [modelUsage, setModelUsage] = useState<
    ProjectModelUsageInterface | null | undefined
  >(null)

  // State (Redux)
  const { projectsState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const {
    currentProject,
    modelList,
    modelListInit,
    currentModel,
    allContentsList,
    contentsList,
    contentsListLoading,
    checkedContentsList,
    contentsPagination,
    contentsKeyword,
    contentsSort,
    reqComponents,
    flattenComponentList,
  } = projectsState
  const { gnb, splitSize } = layoutState

  // State

  // Effect
  useEffect(() => {
    if (
      modelListInit &&
      !modelList.find((m) => m.id === parseInt(modelId as string))
    ) {
      message.error(t('error.pageNotFound'))
      navigate(`/projects/${currentProject?.uid}`)
    }
  }, [modelListInit, modelList, location])

  useEffect(() => {
    initScrollSync()
  }, [gnb, splitSize])

  useEffect(() => {
    if (currentProject && currentModel) {
      contentsListVercitalLayout()

      initScrollSync()
      window.addEventListener('resize', initScrollSync)
    }
  }, [currentProject, currentModel])

  useEffect(() => {
    if (searchParams.get('new') && searchParams.get('new') === 'modal') {
      dispatch(setContentsFormModal(true))
    }

    if (
      currentProject &&
      modelListInit &&
      searchParams.get('edit') &&
      searchParams.get('edit') === 'modal' &&
      searchParams.get('id') &&
      projectUid &&
      modelId
    ) {
      getContentsItem(projectUid, modelId, searchParams.get('id'))
        .then(async (res) => {
          await dispatch(setContentsFormModal(true, res.data.data))

          // 연관 데이터 제목 가져오기
          await getRelationContents(projectUid, res.data.data)
        })
        .catch((e) => {
          message.error(e.response.data.error)
        })
    }

    initScrollSync()
  }, [location, modelListInit, currentProject])

  useEffect(() => {
    if (currentModel && currentProject && currentProject.usage) {
      setModelUsage(
        currentProject.usage.modelList.find((m) => m.id === currentModel.id)
      )
    }
  }, [currentModel, currentProject, currentProject?.usage])

  /**
   * 스크롤 UI 고정 (세로)
   */
  const contentsListVercitalLayout = () => {
    const body = document.getElementById('contents-body')
    const header = document.getElementById('contents-header')
    const tools = document.getElementById('contents-tools')
    const lang = document.getElementById('contents-lang')
    const list = document.getElementById('contents-list')
    const listHeader = document.getElementById('contents-list-header')

    if (body && header && tools && lang && list && listHeader && currentModel) {
      const isFixed = body.scrollTop >= header.clientHeight + tools.clientHeight

      if (isFixed) {
        lang.classList.add('fixed', 'top-0', 'z-40')
        listHeader.classList.add('fixed', 'z-40', 'overflow-x-hidden')
        listHeader.style.top = lang.clientHeight + 'px'
        lang.style.width = body.clientWidth + 'px'
        list.style.paddingTop =
          lang.clientHeight + listHeader.clientHeight + 'px'
      } else {
        lang.classList.remove('fixed', 'top-0')
        listHeader.classList.remove('fixed', 'z-40', 'overflow-x-hidden')
        listHeader.style.top = '0px'
        lang.style.width = 'auto'
        list.style.paddingTop = '0px'
      }

      // Header Cell 고정
      document.querySelectorAll('.header-fixed-cell').forEach((hfc, fIdx) => {
        const headerFixedCell = hfc as HTMLElement

        headerFixedCell.style.left = !isFixed
          ? !fIdx
            ? '0px'
            : LAYOUT_CHECKBOX_CELL_WIDTH + 'px'
          : 'auto'
        headerFixedCell.style.right = isFixed ? '0px' : 'auto'
      })

      contentsListHorizontalLayout()
    }
  }

  /**
   * 스크롤 UI 고정 (가로)
   */
  const contentsListHorizontalLayout = () => {
    const body = document.getElementById('contents-body')
    const header = document.getElementById('contents-header')
    const tools = document.getElementById('contents-tools')
    const lang = document.getElementById('contents-lang')
    const list = document.getElementById('contents-list')
    const listHeader = document.getElementById('contents-list-header')

    if (body && header && tools && lang && list && listHeader && currentModel) {
      const isFixed = body.scrollTop >= header.clientHeight + tools.clientHeight

      // Header Cell 고정
      document.querySelectorAll('.header-cell').forEach((hc, fIdx) => {
        const headerCell = hc as HTMLElement

        headerCell.style.marginLeft =
          (isFixed && fIdx === 0 ? -1 * list.scrollLeft : 0) + 'px'
      })
    }

    syncContentsScroll(false)
  }

  /**
   * 컨텐츠 스크롤 싱크 설정
   */
  const initScrollSync = () => {
    const contentsListHeaderContents = document.getElementById(
      'contents-list-header-contents'
    )
    const contentsBody = document.getElementById('contents-body')
    const contentsList = document.getElementById('contents-list')
    const contentsFakeList = document.getElementById('contents-fake-list')
    const contentsFakeListContents = document.getElementById(
      'contents-fake-list-contents'
    )

    if (
      contentsList &&
      contentsBody &&
      contentsFakeList &&
      contentsFakeListContents
    ) {
      contentsFakeList.style.width = contentsBody.clientWidth + 'px'
      contentsFakeListContents.style.width = contentsListHeaderContents
        ? contentsListHeaderContents?.clientWidth + 'px'
        : 'auto'
    }
  }

  /**
   * 컨텐츠 스크롤 싱크
   * @param isFake
   * @returns
   */
  const syncContentsScroll = (isFake) => {
    const contentsList = document.getElementById('contents-list')
    const contentsFakeList = document.getElementById('contents-fake-list')

    if (!contentsList || !contentsFakeList) return false

    if (scrollTimer !== null) {
      clearTimeout(scrollTimer)
    }
    scrollTimer = setTimeout(function () {
      if (isFake) {
        contentsList.scrollTo({
          left: contentsFakeList.scrollLeft,
        })
      } else {
        contentsFakeList.scrollTo({
          left: contentsList.scrollLeft,
        })
      }
    }, 0)
  }

  /**
   * 콘텐츠 추가 열기
   */
  const openNewFormModal = () => {
    if (
      contentsListLoading ||
      loading ||
      (modelUsage && modelUsage.content.current >= modelUsage.content.limit)
    )
      return false

    navigate(
      `/projects/${currentProject?.uid}/contents/${currentModel?.id}?page=${
        contentsPagination.current
      }&size=${contentsPagination.pageSize}&sort=${contentsSort}&q=${
        searchParams.get('q') ? searchParams.get('q') : ''
      }&new=modal`
    )
  }

  /**
   * 페이지네이션 변경
   * @param page
   * @param pageSize
   */
  const onHandleChangePagination = (page, pageSize) => {
    navigate(
      `/projects/${currentProject?.uid}/contents/${currentModel?.id}?page=${
        contentsPagination.pageSize !== pageSize ? 1 : page
      }&size=${pageSize}&sort=${contentsSort}&q=${
        searchParams.get('q') ? searchParams.get('q') : ''
      }`
    )
  }

  /**
   * 여러 콘텐츠 삭제
   * @param contentsToBeDeleted
   */
  const onContentsListDelete = () => {
    if (checkedContentsList.length) {
      Modal.confirm({
        centered: true,
        title: t('confirmDeleteContentsTitle'),
        icon: <ExclamationCircleOutlined />,
        content: t('confirmDeleteContentsDesc'),
        okText: t('delete'),
        cancelText: t('cancel'),
        onOk() {
          return new Promise((resolve, reject) => {
            deleteMultiContents(
              currentProject?.uid,
              currentModel?.id,
              checkedContentsList.map((cc) => cc.uid)
            )
              .then((res) => {
                // dispatch(setContentsListReload(true))
                dispatch(
                  deleteContentsList(
                    checkedContentsList.map((cc) => cc.uid as string)
                  )
                )
                dispatch(getProjectUsage(currentProject?.uid as string))
                dispatch(setCheckedContentsList([]))
                resolve(res)
              })
              .catch((e) => {
                message.error(e.response.data.error)
                reject(e)
              })
          }).catch((e) => console.log(e))
        },
        onCancel() {},
      })
    }
  }

  /**
   * 컨텐츠 한줄 추가
   */
  const addNewContentsRow = () => {
    if (
      contentsListLoading ||
      loading ||
      (modelUsage && modelUsage.content.current >= modelUsage.content.limit)
    )
      return false

    const req = {
      order: null,
      uid: '',
      cellList: reqComponents,
    }

    setLoading(true)

    createContents(currentProject?.uid, currentModel?.id, req)
      .then((res) => {
        setLoading(false)

        getContentsItem(
          currentProject?.uid,
          currentModel?.id,
          res.data.data
        ).then((res) => {
          dispatch(addContentsList(res.data.data, true))
          dispatch(getProjectUsage(currentProject?.uid as string))
        })
      })
      .catch((e) => {
        message.error(e.response.data.error)
        setLoading(false)
      })
  }

  /**
   * 연관 콘텐츠 정보 가져오기
   * @param project
   * @param contents
   */
  const getRelationContents = (project, contents) => {
    contents?.cellList
      ?.filter((c) => c.component?.type === 'RELATION')
      .forEach((relationCell) => {
        relationCell?.relationList?.forEach((relation) => {
          getContentsItem(project, relation.modelId, relation.uid)
            .then((res) => {
              if (currentProject) {
                const targetModel = modelList.find(
                  (m) => m.id === relation.modelId
                )
                const titleComponent = targetModel?.flattenComponentList?.find(
                  (fc) => fc.option?.title
                )

                const titleCell = res.data.data.cellList.find(
                  (cell) => cell.component.id === titleComponent?.id
                )

                const relationTitle =
                  titleCell && titleCell.languageMap
                    ? titleCell?.languageMap[currentProject.defaultLang]
                    : contents.uid

                const relationModelTitle =
                  targetModel && targetModel.languageMap
                    ? (targetModel.languageMap[
                        currentProject.defaultLang
                      ] as string)
                    : 'N/A'

                dispatch(
                  setContentsCellItem(
                    contents.uid,
                    relationCell.uid,
                    relation.uid,
                    relationTitle,
                    relationModelTitle
                  )
                )

                const titleEles = document.querySelectorAll(
                  `.rel-title-${relationCell.uid}-${relation.uid}`
                )
                const modelTitleEles = document.querySelectorAll(
                  `.rel-modelTitle-${relationCell.uid}-${relation.uid}`
                )

                titleEles.forEach((titleEle, tIdx) => {
                  titleEle.innerHTML = relationTitle
                  modelTitleEles[tIdx].innerHTML = relationModelTitle
                })
              }
            })
            .catch((e) => {
              message.error(e.response.data.error)
            })
        })
      })
  }

  /**
   * 검증 & 배포하기
   */
  const validatePublish = (version?: string) => {
    // 비어있는 콘텐츠 확인
    const requiredComponents = flattenComponentList.filter(
      (fc) => fc.option && fc.option.required
    )
    let isValid = true

    allContentsList.forEach((ac) => {
      requiredComponents.forEach((rc) => {
        const cellToBeRequired: CellInterface | undefined | null = ac.cellList
          ? ac.cellList.find(
              (cell) => cell.component && cell.component.id === rc.id
            )
          : null

        if (!cellToBeRequired) {
          isValid = false
          return false
        }

        if (
          cellToBeRequired.component &&
          (cellToBeRequired.component.type === 'SINGLE_LINE_TEXT' ||
            cellToBeRequired.component.type === 'LONG_LINE_TEXT')
        ) {
          currentProject?.languageList.forEach((lang, lIdx) => {
            if (
              !cellToBeRequired.languageMap ||
              !cellToBeRequired.languageMap[lang]
            ) {
              isValid = false
              return false
            }
          })
        }

        if (
          !cellToBeRequired ||
          (cellToBeRequired &&
            cellToBeRequired.component &&
            ((cellToBeRequired.component.type !== 'SINGLE_LINE_TEXT' &&
              cellToBeRequired.component.type !== 'LONG_LINE_TEXT' &&
              cellToBeRequired.component.type !== 'CATEGORY' &&
              cellToBeRequired.component.type !== 'RELATION' &&
              !cellToBeRequired.value) ||
              (cellToBeRequired.component.type === 'CATEGORY' &&
                (!cellToBeRequired.selectorList ||
                  cellToBeRequired.selectorList.length === 0)) ||
              (cellToBeRequired.component.type === 'RELATION' &&
                (!cellToBeRequired.relationList ||
                  cellToBeRequired.relationList.length === 0))))
        ) {
          isValid = false
          return false
        }
      })
    })

    if (!isValid) {
      message.error(t('error.requiredDetected'))
      return false
    }

    Modal.confirm({
      centered: true,
      title: t('validatePublish'),
      icon: <ExclamationCircleOutlined />,
      content: t('validatePublishDesc'),
      okText: t('validatePublish'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          validateModel(currentProject?.uid, currentModel?.id, version)
            .then((res) => {
              message.success(t('validatePublishComplete'))
              dispatch(getModels(currentProject?.uid, currentModel?.id, true))
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  return (
    <LayoutProjects>
      {currentProject && currentModel && (
        <Helmet>
          <title>
            {currentModel.languageMap.KO} · {t('contents')} ·{' '}
            {currentProject.name} · {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject && currentModel ? (
        <div className={'flex flex-col h-screen overflow-hidden'}>
          <div
            id="contents-body"
            onScroll={() => contentsListVercitalLayout()}
            className="grow h-full relative overflow-hidden overflow-y-auto">
            {/* Contents header: 시작 */}
            <div
              id="contents-header"
              className="grow-0 bg-gray-200 flex-none px-6 py-4 border-b border-gray-300 flex justify-between items-center">
              <div>
                <h1
                  className={
                    'mb-0 text-lg leading-7 flex items-center space-x-4'
                  }>
                  <span>
                    {currentModel?.languageMap[currentProject.defaultLang]}
                  </span>
                  <span className="text-xs font-normal text-gray-600">
                    {currentModel?.devKey}
                  </span>
                  {currentModel.lastValidate ? (
                    <Tooltip
                      title={t('lastValidate', {
                        date: moment(
                          currentModel.lastValidate,
                          'YYYYMMDDHHmmss'
                        ).format('YYYY-MM-DD HH:mm:ss'),
                      })}>
                      <CloudSyncOutlined
                        style={{
                          color: LAYOUT_SUCCESS_COLOR,
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </h1>
              </div>
              {currentProject.role === 'ADMIN' ? (
                <div className={'space-x-2.5'}>
                  <Button
                    type={'primary'}
                    ghost
                    onClick={() =>
                      navigate(
                        `/projects/${currentProject.uid}/builder/${currentModel.id}`
                      )
                    }
                    disabled={contentsListLoading}
                    title={t('editModel')}>
                    {t('editModel')}
                  </Button>
                  {flattenComponentList &&
                  flattenComponentList.length &&
                  allContentsList &&
                  allContentsList.length ? (
                    <Button
                      icon={<CloudUploadOutlined />}
                      onClick={() => validatePublish('v2')}
                      type={'primary'}
                      disabled={contentsListLoading}
                      title={t('validatePublish')}>
                      {t('validatePublish')}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* Contents header: 끝 */}
            {/* Contents tools: 시작 */}
            <div
              id="contents-tools"
              className="grow-0 bg-gray-200 flex-none h-12 px-6 border-b border-gray-300 flex items-center w-full">
              <ContentsTools />
            </div>
            {/* Contents tools: 끝 */}
            {/* Contents 언어: 시작 */}
            <div
              id="contents-lang"
              className="grow-0 bg-gray-200 flex-none h-12 flex justify-between items-center px-6">
              <div className="flex space-x-3 items-center">
                <div className="text-gray-600">
                  {t('noOfContents', { no: contentsPagination.total })}
                </div>
                {currentProject.usage?.type !== 'UNLIMITED' ? (
                  <Tooltip
                    title={t('noContentsByModelDesc')}
                    className="cursor-help">
                    <div
                      className="flex space-x-1 items-center text-xs"
                      style={{
                        color:
                          modelUsage &&
                          modelUsage.content.current >= modelUsage.content.limit
                            ? LAYOUT_WARNING_COLOR
                            : LAYOUT_OKAY_COLOR,
                      }}>
                      <span>
                        {modelUsage?.content.current} /{' '}
                        {modelUsage?.content.limit}
                      </span>
                      <InfoCircleOutlined className="mt-px" />
                    </div>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex items-center gap-x-4">
                <ContentsLanguage />
                {checkedContentsList.length &&
                currentProject?.role !== 'VIEWER' ? (
                  <div>
                    <Button
                      danger
                      icon={<DeleteFilled />}
                      onClick={() => onContentsListDelete()}>
                      {t('delete')}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* Contents 언어: 끝 */}
            {/* Contents list: 시작 */}
            <div
              id="contents-list"
              onScroll={() => contentsListHorizontalLayout()}
              className="grow overflow-x-auto min-h-full pb-32 relative">
              <ContentsListExcel />
              {/* Contents Row 추가: 시작 */}
              {currentProject.role !== 'VIEWER' &&
              flattenComponentList &&
              flattenComponentList.length ? (
                <div
                  id="contents-add-row"
                  className={`sticky left-0 w-full grow-0 bg-gray-200 hover:bg-gray-300 flex-none h-8 flex items-center px-4 border-b border-r border-gray-300 ${
                    contentsListLoading ||
                    loading ||
                    (modelUsage &&
                      modelUsage.content.current >= modelUsage.content.limit)
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => addNewContentsRow()}
                  title={t('addContents')}>
                  <button
                    type="button"
                    disabled={
                      contentsListLoading ||
                      loading ||
                      !!(
                        modelUsage &&
                        modelUsage.content.current >= modelUsage.content.limit
                      )
                    }
                    className={`sticky left-4 flex space-x-6 items-center text-gray-600 ${
                      contentsListLoading ||
                      loading ||
                      (modelUsage &&
                        modelUsage.content.current >= modelUsage.content.limit)
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}>
                    <PlusOutlined />
                    <span>{t('addContents')}</span>
                  </button>
                </div>
              ) : (
                <></>
              )}
              {/* Contents Row 추가: 끝 */}
            </div>
            <div
              id="contents-fake-list"
              className="fixed h-4 bottom-24 right-0 overflow-x-auto z-50"
              onScroll={syncContentsScroll}>
              <div id="contents-fake-list-contents" className="h-4"></div>
            </div>
            {/* Contents list: 끝 */}
          </div>
          {/* Contents 추가: 시작 */}
          {currentProject.role !== 'VIEWER' &&
          flattenComponentList &&
          flattenComponentList.length ? (
            <div
              id="contents-add"
              className={`z-50 grow-0 bg-gray-200 hover:bg-gray-300 flex-none h-12 flex items-center px-6 border-t border-gray-300 ${
                contentsListLoading ||
                loading ||
                (modelUsage &&
                  modelUsage.content.current >= modelUsage.content.limit)
                  ? 'cursor-not-allowed opacity-50'
                  : 'cursor-pointer'
              }`}
              onClick={() => openNewFormModal()}
              title={t('addContents')}>
              <button
                type="button"
                disabled={
                  contentsListLoading ||
                  loading ||
                  !!(
                    modelUsage &&
                    modelUsage.content.current >= modelUsage.content.limit
                  )
                }
                className={`flex space-x-2.5 items-center text-gray-600 ${
                  contentsListLoading ||
                  loading ||
                  (modelUsage &&
                    modelUsage.content.current >= modelUsage.content.limit)
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
                }`}>
                <ArrowsAltOutlined />
                <span>{t('addContents')}</span>
              </button>
            </div>
          ) : (
            <></>
          )}
          {/* Contents 추가: 끝 */}
          {/* Pagination: 시작 */}
          <div
            id="contents-pagination"
            className="z-50 grow-0 bg-gray-200 flex-none h-12 flex justify-between items-center px-6 border-t border-gray-300">
            <div className="text-gray-600">
              {t('noOfContents', { no: contentsPagination.total })}
            </div>
            <Pagination
              defaultCurrent={1}
              current={contentsPagination.current}
              total={contentsPagination.total}
              pageSize={contentsPagination.pageSize}
              onChange={onHandleChangePagination}
            />
          </div>
          {/* Pagination: 끝 */}
          {currentProject?.role === 'ADMIN' ? <ModelsFormModal /> : <></>}
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </LayoutProjects>
  )
}

export default ProjectsContentsByModel
