import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '@/states/reducers'
import { Tooltip, Button, message, Collapse } from 'antd'
import { CopyOutlined, LinkOutlined } from '@ant-design/icons'
import { setCurrentModel } from '@/states/actions/models.actions'
import { copyStr, getSavedContentsSortKey } from '@/utils/helpers'
import { DEFAULT_CONTENTS_PAGE_SIZE } from '@/configs'
import { ComponentsOptionDesc } from '@/components/components/components.optionDesc'
import { ModelInterface } from '@/types'
import Prism from 'prismjs'
import { useAppDispatch } from '@/states/store'

interface DevelopersExternalAPIsSpecsProps {
  model: ModelInterface
}

export const DevelopersExternalAPIsSpecs = ({
  model,
}: DevelopersExternalAPIsSpecsProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, contentsListLoading } = projectsState

  // State
  const [responseDataStr, setResponseDataStr] = useState<string>('')

  // Effect
  useEffect(() => {
    if (currentProject && model) {
      let str = ''
      model?.flattenComponentList
        ?.filter((fc) => fc.type !== 'BLOCK')
        .forEach((fc, cIdx) => {
          let valueStr = ''

          if (fc.type === 'SINGLE_LINE_TEXT' || fc.type === 'LONG_LINE_TEXT') {
            valueStr += `{
            `
            currentProject.languageList.forEach((l, lIdx) => {
              valueStr += `${l}: "string text"${
                lIdx < currentProject.languageList.length - 1
                  ? `,
            `
                  : ''
              }`
            })

            valueStr += `
        }`
          } else if (fc.type === 'RICH_TEXT') {
            valueStr += `{
            `
            currentProject.languageList.forEach((l, lIdx) => {
              valueStr += `${l}: ${`'{"time":1661923195400,"blocks":[{"id":"KLsGt6nWLO","type":"header","data":{"text":"Editor.js","level":2}},{"id":"lBeeaZKn5-","type":"paragraph","data":{"text":"Hey.MeetthenewEditor.Onthispageyoucanseeitinaction—trytoeditthistext."}},{"id":"lHFL6OxZvV","type":"list","data":{"style":"unordered","items":["Itisablock-stylededitor","ItreturnscleandataoutputinJSON","DesignedtobeextendableandpluggablewithasimpleAPI"]}},{"id":"Av_SK2J99g","type":"delimiter","data":{}},{"id":"9fGxQNTWEY","type":"image","data":{"file":{"url":"https://codex.so/public/app/img/external/codex2x.png"},"caption":"","withBorder":false,"stretched":false,"withBackground":false}}],"version":"2.25.0"}'`}${
                lIdx < currentProject.languageList.length - 1
                  ? `,
            `
                  : ''
              }`
            })

            valueStr += `
        }`
          } else if (fc.type === 'CATEGORY') {
            valueStr += `[
            {
                "_id": 10,
                "order": 1,
                "languageMap": {
                  `
            currentProject.languageList.forEach((l, lIdx) => {
              valueStr += `${l}: "category text"${
                lIdx < currentProject.languageList.length - 1
                  ? `,
                `
                  : ''
              }`
            })

            valueStr += `
                }
            }
            ...
        ]`
            valueStr += `
        `
          } else if (fc.type === 'RELATION') {
            valueStr += `[
          "56f3e9d416674c0293fe5a9ffaa2e794",
          "7f374c3cc0164badb6b5ecee289b0c52",
          ...
        ]`
          } else if (fc.type === 'BOOLEAN') {
            valueStr += 'true'
          } else if (fc.type === 'EMAIL') {
            valueStr += 'email@email.com'
          } else if (fc.type === 'NUMBER') {
            valueStr += fc.option?.max ? fc.option?.max : '123'
          } else if (fc.type === 'DATE') {
            valueStr += '"2022-01-01T00:00:00"'
          } else if (fc.type === 'PASSWORD') {
            valueStr += 'pw1234!@#$%'
          } else if (fc.type === 'MEDIA') {
            let langStr = ''
            langStr += `{
              `
            currentProject.languageList.forEach((l, lIdx) => {
              langStr += `    ${l}: "string text"${
                lIdx < currentProject.languageList.length - 1
                  ? `,
              `
                  : ''
              }`
            })

            langStr += `
                }`

            valueStr += `[
          {
            "id": 20,
            "mediaType": "FILE",
            "fileType": "IMAGE",
            "file": {
              "id": 10,
              "type": "IMAGE",
              "name": "file_name.jpg",
              "path": "https://image.memexdata.io/iiif/.../default.jpg",
              "meta": {
                "size": "875 KB",
                "type": "image/jpeg",
                "height": 1280,
                "width": 1920
              },
              "thumbnailList": [
                {
                  "id": 1324,
                  "path": "https://image.memexdata.io/iiif/.../default.jpg",
                  "type": "SMALL",
                  "meta": {
                    "size": "4 KB",
                    "type": "image/jpeg",
                    "height": 133,
                    "width": 200
                  }
                },
                ...
              ]
            },
            "languageMap": ${langStr}
          },
          ...
        ]`
          }

          str += `"${fc.devKey}": ${valueStr}${
            model.flattenComponentList &&
            cIdx <
              model.flattenComponentList.filter((fc) => fc.type !== 'BLOCK')
                .length -
                1
              ? `,
        `
              : ''
          }`
        })

      setResponseDataStr(str)

      setTimeout(() => {
        Prism.highlightAll()
      })
    }
  }, [currentProject, model])

  /**
   * 컨텐츠 편집 이동 변경
   * @param model
   * @returns
   */
  const goContentsByModel = (model) => {
    if (contentsListLoading || !model) return
    dispatch(setCurrentModel(model))

    navigate(
      `/projects/${currentProject?.uid}/contents/${
        model.id
      }?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
        model.id
      )}&q=`
    )
  }

  /**
   * API 주소 복사
   * @param str
   */
  const copyApi = (str) => {
    if (copyStr(str)) {
      message.success(t('copied'))
    }
  }

  /**
   * 코드 Prism 스타일 적용
   */
  const highlightCode = () => {
    setTimeout(() => {
      Prism.highlightAll()
    }, 0)
  }

  return currentProject ? (
    <div key={model.devKey} id={`${model.devKey}`} className="space-y-10">
      <hr className="mb-20" />
      <div className="flex justify-between space-x-2">
        <div className="flex items-center space-x-2">
          <h2 className="text-2xl font-medium mb-0 break-all">
            {model.languageMap[currentProject.defaultLang]} ({model.devKey})
            <a href={`#${model.devKey}`} className="display-inline ml-2">
              <LinkOutlined />
            </a>
          </h2>
        </div>
        <Button
          type={'primary'}
          ghost
          className="flex-none"
          onClick={() => goContentsByModel(model)}
          title={t('editContents')}>
          {t('editContents')}
        </Button>
      </div>
      <Collapse
        accordion
        defaultActiveKey={['list']}
        onChange={() => highlightCode()}>
        <Collapse.Panel
          header={`POST ${t('getContentsList')} - ${
            model.languageMap[currentProject.defaultLang]
          }`}
          key="list">
          <div className="space-y-10">
            <div>
              <div className="flex justify-between items-center">
                <h4 className="font-medium">URL</h4>
                <Tooltip title={t('clickToCopy')} className="cursor-help">
                  <Button
                    type={'text'}
                    icon={<CopyOutlined />}
                    onClick={() =>
                      copyApi(`${process.env.REACT_APP_API_URL}api/projects/
              ${currentProject?.uid}
              /models/${model.devKey}
              /contents/search/v1`)
                    }
                  />
                </Tooltip>
              </div>
              <div className="relative">
                <pre>
                  <code className="language-js">
                    {`POST ${process.env.REACT_APP_API_URL}api/projects/${currentProject?.uid}/models/${model.devKey}/contents/search/v1
Content-Type: application/json
Access-Token: {AccessToken}
X-Forwarded-Host: {Domain}`}
                  </code>
                </pre>
              </div>
            </div>
            <div>
              <h4 className="font-medium">Request (JSON)</h4>
              <pre>
                <code className="language-js">
                  {`{
    "size" : 10,
    "page" : 0
}`}
                </code>
              </pre>
              <table className="w-full table-fixed border-collapse border border-slate-400">
                <colgroup>
                  <col width={'25%'} />
                  <col width={'25%'} />
                  <col width={'100px'} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th className="border border-slate-300 p-3">{t('name')}</th>
                    <th className="border border-slate-300 p-3">{t('type')}</th>
                    <th className="border border-slate-300 p-3">
                      {t('optionsType.required.name')}
                    </th>
                    <th className="border border-slate-300 p-3">
                      {t('description')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-slate-300 p-3">size</td>
                    <td className="border border-slate-300 p-3 ">NUMBER</td>
                    <td className="border border-slate-300 p-3 text-center">
                      O
                    </td>
                    <td className="border border-slate-300 p-3">Paging Size</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-300 p-3">page</td>
                    <td className="border border-slate-300 p-3 ">NUMBER</td>
                    <td className="border border-slate-300 p-3 text-center">
                      O
                    </td>
                    <td className="border border-slate-300 p-3">
                      Paging Number (start from 0)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="space-y-10">
              <div>
                <h5 className="font-medium">Response (Example)</h5>
                <div className="relative">
                  <pre>
                    <code className="language-javascript">
                      {`HTTP 200 OK
Content-Type: application/json;charset=UTF-8
{
  "list":[
    {
      "uid": "f325z1a813054621b794b7cc9e7a1233",
      "order": 1,
      "data": {
        ${responseDataStr}
      }
    }
  ],
  "pageInfo": {
    "size": 20,
    "page": 0,
    "totalPages": 10,
    "totalElements": 200,
    "isLast": false
  }
}`}
                    </code>
                  </pre>
                </div>
              </div>
              <div>
                <h5 className="font-medium">Response (list)</h5>
                <table className="w-full table-fixed border-collapse border border-slate-400">
                  <colgroup>
                    <col width={'20%'} />
                    <col width={'20%'} />
                    <col width={'20%'} />
                    <col width={'100px'} />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="border border-slate-300 p-3">
                        {t('devKey')}
                      </th>
                      <th className="border border-slate-300 p-3">
                        {t('name')}
                      </th>
                      <th className="border border-slate-300 p-3">
                        {t('componentType')}
                      </th>
                      <th className="border border-slate-300 p-3">
                        {t('optionsType.required.name')}
                      </th>
                      <th className="border border-slate-300 p-3">
                        {t('options')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {model.flattenComponentList ? (
                      model.flattenComponentList
                        .filter((fc) => fc.type !== 'BLOCK')
                        .map((comp) => (
                          <tr key={comp.devKey}>
                            <td className="border border-slate-300 p-3">
                              {comp.devKey}
                            </td>
                            <td className="border border-slate-300 p-3">
                              {comp.languageMap[currentProject.defaultLang]}
                            </td>
                            <td className="border border-slate-300 p-3 break-words">
                              {comp.type}
                            </td>
                            <td className="border border-slate-300 p-3 text-center">
                              {comp.option && comp.option.required ? 'O' : ''}
                            </td>
                            <td className="border border-slate-300 p-3">
                              {comp.option ? (
                                <ul className="mb-0 list-disc pl-4">
                                  {Object.keys(comp.option).map((key, kIdx) => (
                                    <ComponentsOptionDesc
                                      key={kIdx}
                                      componentType={comp.type}
                                      // @ts-ignore
                                      componentOptionType={key}
                                      value={comp.option && comp.option[key]}
                                    />
                                  ))}
                                </ul>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
              <div>
                <h5 className="font-medium">Response (pageInfo)</h5>
                <table className="w-full table-fixed border-collapse border border-slate-400">
                  <colgroup>
                    <col width={'25%'} />
                    <col width={'25%'} />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="border border-slate-300 p-3">
                        {t('name')}
                      </th>
                      <th className="border border-slate-300 p-3">
                        {t('type')}
                      </th>
                      <th className="border border-slate-300 p-3">
                        {t('description')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-slate-300 p-3">size</td>
                      <td className="border border-slate-300 p-3 ">NUMBER</td>
                      <td className="border border-slate-300 p-3">
                        Paging Size
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-300 p-3">page</td>
                      <td className="border border-slate-300 p-3 ">NUMBER</td>
                      <td className="border border-slate-300 p-3">
                        Paging Number (start from 0)
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-300 p-3">
                        totalPages
                      </td>
                      <td className="border border-slate-300 p-3 ">NUMBER</td>
                      <td className="border border-slate-300 p-3">
                        Total Pages
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-300 p-3">
                        totalElements
                      </td>
                      <td className="border border-slate-300 p-3 ">NUMBER</td>
                      <td className="border border-slate-300 p-3">
                        Total Elements
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-slate-300 p-3">isLast</td>
                      <td className="border border-slate-300 p-3 ">BOOLEAN</td>
                      <td className="border border-slate-300 p-3">
                        Is Last Page
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          header={`GET ${t('getContentsItem')} - ${
            model.languageMap[currentProject.defaultLang]
          }`}
          key="item">
          <div className="space-y-10">
            <div>
              <div className="flex justify-between items-center">
                <h4 className="font-medium">URL</h4>
                <Tooltip title={t('clickToCopy')} className="cursor-help">
                  <Button
                    type={'text'}
                    icon={<CopyOutlined />}
                    onClick={() =>
                      copyApi(`${process.env.REACT_APP_API_URL}api/projects/
              ${currentProject?.uid}
              /models/${model.devKey}
              /contents/{uid}/v1`)
                    }
                  />
                </Tooltip>
              </div>
              <div className="relative">
                <pre>
                  <code className="language-js">
                    {`GET ${process.env.REACT_APP_API_URL}api/projects/${currentProject?.uid}/models/${model.devKey}/contents/{uid}/v1
Content-Type: application/json
Access-Token: {AccessToken}
X-Forwarded-Host: {Domain}`}
                  </code>
                </pre>
              </div>
            </div>
            <div>
              <h5 className="font-medium">Response (Example)</h5>
              <div className="relative">
                <pre>
                  <code className="language-javascript">
                    {`HTTP 200 OK
Content-Type: application/json;charset=UTF-8
{
  "data":{
    "uid": "f325z1a813054621b794b7cc9e7a1233",
    "order": 1,
    "data": {
      ${responseDataStr}
    }
  }
}`}
                  </code>
                </pre>
              </div>
            </div>
            <div>
              <h5 className="font-medium">Response (item)</h5>
              <table className="w-full table-fixed border-collapse border border-slate-400">
                <colgroup>
                  <col width={'20%'} />
                  <col width={'20%'} />
                  <col width={'20%'} />
                  <col width={'100px'} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th className="border border-slate-300 p-3">
                      {t('devKey')}
                    </th>
                    <th className="border border-slate-300 p-3">{t('name')}</th>
                    <th className="border border-slate-300 p-3">
                      {t('componentType')}
                    </th>
                    <th className="border border-slate-300 p-3">
                      {t('optionsType.required.name')}
                    </th>
                    <th className="border border-slate-300 p-3">
                      {t('options')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {model.flattenComponentList ? (
                    model.flattenComponentList
                      .filter((fc) => fc.type !== 'BLOCK')
                      .map((comp) => (
                        <tr key={comp.devKey}>
                          <td className="border border-slate-300 p-3">
                            {comp.devKey}
                          </td>
                          <td className="border border-slate-300 p-3">
                            {comp.languageMap[currentProject.defaultLang]}
                          </td>
                          <td className="border border-slate-300 p-3 break-words">
                            {comp.type}
                          </td>
                          <td className="border border-slate-300 p-3 text-center">
                            {comp.option && comp.option.required ? 'O' : ''}
                          </td>
                          <td className="border border-slate-300 p-3">
                            {comp.option ? (
                              <ul className="mb-0 list-disc pl-4">
                                {Object.keys(comp.option).map((key, kIdx) => (
                                  <ComponentsOptionDesc
                                    key={kIdx}
                                    componentType={comp.type}
                                    // @ts-ignore
                                    componentOptionType={key}
                                    value={comp.option && comp.option[key]}
                                  />
                                ))}
                              </ul>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  ) : (
    <></>
  )
}
