import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { message, Menu } from 'antd'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import { setAccountMenu } from '@/states/actions/layout.actions'
import { logout } from '@/states/actions/auth.actions'
import { setLogout } from '@/utils/auth'
import { LAYOUT_GNB_WIDTH } from '@/configs'
import { useAppDispatch } from '@/states/store'
import { CommonLinks } from '../common/common.links'

export const AuthProfile = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { layoutState, authState, projectsState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      authState: state.auth,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { accountMenu, gnb } = layoutState
  const { me } = authState
  const { contentsListLoading } = projectsState

  /**
   * 로그아웃
   */
  const goLogout = () => {
    logout()
      .then((res) => {
        setLogout(true)
      })
      .catch((e) => {
        message.error(t('error.wrong'))
      })
  }

  /**
   * 프로필 메뉴
   */
  const accountProfileMenus = [
    {
      key: 'projects',
      label: t('goProjectList'),
      onClick: (e) => {
        navigate('/projects')
      },
    },
    {
      key: 'settings',
      label: t('myInformation'),
      onClick: (e) => {
        navigate('/settings/account')
      },
    },
    {
      key: 'logout',
      label: t('logout'),
      onClick: (e) => {
        goLogout()
      },
    },
  ]

  return (
    <div className={'absolute left-0 bottom-0 w-full'}>
      {!process.env.REACT_APP_PROJECT_ID && gnb ? <CommonLinks /> : <></>}
      <div
        className={'px-5 h-12 py-1.5 border-t cursor-pointer'}
        onClick={() =>
          dispatch(setAccountMenu(!contentsListLoading ? !accountMenu : false))
        }>
        <div className={`w-full flex items-center ${gnb ? 'space-x-3' : ''}`}>
          {me?.image && me?.image?.path ? (
            <div
              className={`${
                !gnb ? 'w-6 h-6' : 'w-8 h-8'
              } rounded-full bg-gray-300 flex-none bg-cover bg-center`}
              style={{ backgroundImage: `url(${me?.image?.path})` }}></div>
          ) : (
            <div
              className={`${
                !gnb ? 'w-6 h-6' : 'w-8 h-8'
              } rounded-full bg-gray-300 flex-none memex-ico-user`}></div>
          )}
          {gnb ? (
            <div className={'grow overflow-hidden'}>
              <div className={'leading-5 truncate'}>{me?.name}</div>
              <div
                className={'text-xs text-gray-500 leading-4 -mt-0.5 truncate'}>
                {me?.email}
              </div>
            </div>
          ) : (
            <></>
          )}
          {gnb ? (
            <div className={'w-4 flex-none'}>
              {accountMenu ? <CaretDownOutlined /> : <CaretUpOutlined />}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <nav
        className={`absolute left-0 border border-r-0 border-l-0 border-b-0 bottom-12 stay-open ${
          accountMenu ? '' : 'hidden'
        }`}
        style={{
          width: gnb ? '100%' : LAYOUT_GNB_WIDTH + 'px',
          zIndex: 200,
        }}>
        <Menu
          theme="dark"
          defaultSelectedKeys={[]}
          mode="inline"
          onClick={() => dispatch(setAccountMenu(false))}
          items={accountProfileMenus}
        />
      </nav>
    </div>
  )
}
