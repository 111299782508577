import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { LayoutProjects } from '@/components/layout/layout.projects'
import { Card, Spin, Tooltip, Anchor, Row, Col, Button, message } from 'antd'
const { Link } = Anchor
import { RootState } from '@/states/reducers'
import { DevelopersExternalAPIsSpecs } from '@/components/developers'
import { useNavigate } from 'react-router'
import { useAppDispatch } from '@/states/store'

const ProjectsDevelopersExternalAPIs = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState

  return (
    <LayoutProjects>
      {currentProject && (
        <Helmet>
          <title>
            {t('externalAPIs')} · {t('developers')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className={'py-4 px-6'}>
          <div className="mb-8">
            <h1
              className={'mb-0 text-lg leading-7 flex items-center space-x-4'}>
              {t('externalAPIs')}
            </h1>
          </div>
          <Row gutter={28}>
            <Col span={18}>
              <Card>
                <div className="p-3 pb-20">
                  {/* 본문: 시작 */}
                  <div className="space-y-20">
                    <div className="space-y-6">
                      <p>{t('externalAPIsDescription')}</p>
                      <Button
                        type={'primary'}
                        onClick={() =>
                          navigate(
                            `/projects/${currentProject.uid}/developers/apps`
                          )
                        }
                        title={t('manageApiKey')}>
                        {t('manageApiKey')}
                      </Button>
                    </div>
                    {modelList.map((model, mIdx) => (
                      <DevelopersExternalAPIsSpecs
                        key={model.devKey}
                        model={model}
                      />
                    ))}
                  </div>
                  {/* 본문: 끝 */}
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <div className="px-3">
                {/* Anchor: 시작 */}
                <Anchor
                  // @ts-ignore
                  getContainer={() => document.getElementById('layout-main')}
                  offsetTop={0}
                  className="py-3">
                  {modelList.map((model) => (
                    <Link
                      key={model.devKey}
                      href={`#${model.devKey}`}
                      title={`${
                        model.languageMap[currentProject.defaultLang]
                      } (${model.devKey})`}
                    />
                  ))}
                </Anchor>
                {/* Anchor: 끝 */}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </LayoutProjects>
  )
}

export default ProjectsDevelopersExternalAPIs
