import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import {
  Button,
  Table,
  Spin,
  Typography,
  Dropdown,
  Menu,
  Modal,
  message,
} from 'antd'
import {
  EllipsisOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { LayoutProjects } from '@/components/layout/layout.projects'
import { RootState } from '@/states/reducers'
import { setProjectAccessFormModal } from '@/states/actions/modals.actions'
import { ProjectsApiKeyModal } from '@/components/projects'
import {
  deleteProjectAccess,
  getProjectAccess,
} from '@/states/actions/projects.actions'
import { useAppDispatch } from '@/states/store'

const ProjectsDevelopersApps = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, accessList } = projectsState

  // Effect
  useEffect(() => {
    if (currentProject) {
      dispatch(getProjectAccess(currentProject.uid))
    }
  }, [currentProject])

  // Table columns
  const tableCols = [
    {
      title: t('name'),
      key: 'name',
      render: (projectApiKey) => (
        <div
          className={'flex items-center space-x-3 cursor-pointer text-blue-500'}
          onClick={() =>
            dispatch(setProjectAccessFormModal(true, projectApiKey))
          }>
          <div>{projectApiKey.name}</div>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('description'),
      key: 'description',
      render: (projectApiKey) => (
        <div
          className={'flex items-center space-x-3 cursor-pointer'}
          onClick={() =>
            dispatch(setProjectAccessFormModal(true, projectApiKey))
          }>
          <div>{projectApiKey.description}</div>
        </div>
      ),
    },
    {
      title: t('allowedDomain'),
      key: 'allowedDomain',
      render: (projectApiKey) => (
        <div
          className={'flex items-center space-x-3 cursor-pointer'}
          onClick={() =>
            dispatch(setProjectAccessFormModal(true, projectApiKey))
          }>
          <div
            dangerouslySetInnerHTML={{
              __html: projectApiKey.list.join('\n').replace(/\n/g, '<br />'),
            }}></div>
        </div>
      ),
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      render: (projectApiKey) =>
        currentProject?.role === 'ADMIN' ? (
          <Dropdown
            overlay={projectApiKeyMenus(projectApiKey)}
            trigger={['click']}>
            <Button
              type={'text'}
              icon={<EllipsisOutlined></EllipsisOutlined>}
              title={t('more')}></Button>
          </Dropdown>
        ) : (
          <></>
        ),
    },
  ]

  /**
   * 프로젝트 API 액션 메뉴
   * @param model
   * @returns
   */
  const projectApiKeyMenusItem = (projectApiKey) => {
    return [
      {
        key: 'edit',
        label: t('editAccess'),
        onClick: () => {
          dispatch(setProjectAccessFormModal(true, projectApiKey))
        },
      },
      {
        key: 'delete',
        label: t('deleteAccess'),
        onClick: () => {
          onProjectApiKeyDelete(projectApiKey)
        },
      },
    ]
  }

  /**
   * 프로젝트 API 액션 툴
   * @param projectApiKey
   */
  const projectApiKeyMenus = (projectApiKey) => (
    <Menu className={'w-48'} items={projectApiKeyMenusItem(projectApiKey)} />
  )

  /**
   * 프로젝트 API Key 삭제 confirm
   * @param projectApiKey
   */
  const onProjectApiKeyDelete = (projectApiKey) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteProjectAccessTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteProjectAccessDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteProjectAccess(currentProject?.uid, projectApiKey.id)
            .then((res) => {
              dispatch(getProjectAccess(currentProject?.uid as string))
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  return (
    <LayoutProjects>
      {currentProject && (
        <Helmet>
          <title>
            {t('appSettings')} · {t('developers')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className={'py-4 px-6'}>
          {/* Setting header: 시작 */}
          <div className="mb-10 flex justify-between">
            <div>
              <h1 className={'mb-0 text-lg leading-7'}>{t('appSettings')}</h1>
            </div>
            {currentProject.role === 'ADMIN' ? (
              <div>
                <Button
                  type={'primary'}
                  icon={<PlusOutlined />}
                  onClick={() => dispatch(setProjectAccessFormModal(true))}
                  title={t('createAccess')}>
                  {t('createAccess')}
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* Setting header: 끝 */}
          {/* Setting body: 시작 */}
          <div className={'space-y-6'}>
            <div>
              <Typography.Title level={5} className={'mt-0'}>
                {t('apiKey')}
              </Typography.Title>
            </div>
            <div>
              <Table
                // @ts-ignore
                columns={tableCols}
                dataSource={accessList}
                rowKey="id"
                pagination={false}></Table>
            </div>
          </div>
          {/* Setting body: 끝 */}
          <ProjectsApiKeyModal />
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </LayoutProjects>
  )
}

export default ProjectsDevelopersApps
