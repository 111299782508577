import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '@/states/reducers'
import { Tooltip, Button, message } from 'antd'
import { CopyOutlined, LinkOutlined } from '@ant-design/icons'
import { setCurrentModel } from '@/states/actions/models.actions'
import { copyStr, getSavedContentsSortKey } from '@/utils/helpers'
import { DEFAULT_CONTENTS_PAGE_SIZE } from '@/configs'
import { ModelInterface } from '@/types'
import Prism from 'prismjs'
import { useAppDispatch } from '@/states/store'

interface DevelopersTypescriptProps {
  model: ModelInterface
}

export const DevelopersTypescript = ({ model }: DevelopersTypescriptProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, contentsListLoading } = projectsState

  // State
  const [typeStr, setTypeStr] = useState<string>('')

  // Effect
  useEffect(() => {
    if (currentProject && model) {
      let str = ''
      model?.flattenComponentList
        ?.filter((fc) => fc.type !== 'BLOCK')
        .forEach((fc, cIdx) => {
          let valueStr = ''

          if (fc.type === 'SINGLE_LINE_TEXT' || fc.type === 'LONG_LINE_TEXT') {
            valueStr += 'LanguageMap'
          } else if (fc.type === 'RICH_TEXT') {
            valueStr += 'LanguageMap'
          } else if (fc.type === 'CATEGORY') {
            valueStr += 'CategoryInterface[]'
          } else if (fc.type === 'RELATION') {
            valueStr += 'string[]'
          } else if (fc.type === 'BOOLEAN') {
            valueStr += 'boolean'
          } else if (fc.type === 'EMAIL') {
            valueStr += 'string'
          } else if (fc.type === 'NUMBER') {
            valueStr += 'number'
          } else if (fc.type === 'DATE') {
            valueStr += 'string'
          } else if (fc.type === 'PASSWORD') {
            valueStr += 'string'
          } else if (fc.type === 'MEDIA') {
            valueStr += 'MediaInterface[]'
          }

          str += `${fc.devKey}: ${valueStr}${
            model.flattenComponentList &&
            cIdx <
              model.flattenComponentList.filter((fc) => fc.type !== 'BLOCK')
                .length -
                1
              ? `,
  `
              : ''
          }`
        })

      setTypeStr(str)

      setTimeout(() => {
        Prism.highlightAll()
      })
    }
  }, [currentProject, model])

  /**
   * 컨텐츠 편집 이동 변경
   * @param model
   * @returns
   */
  const goContentsByModel = (model) => {
    if (contentsListLoading || !model) return

    navigate(
      `/projects/${currentProject?.uid}/contents/${
        model.id
      }?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
        model.id
      )}&q=`
    )
  }

  /**
   * 문자 복사
   * @param str
   */
  const copyInterface = (str) => {
    if (copyStr(str)) {
      message.success(t('copied'))
    }
  }

  return currentProject ? (
    <div key={model.devKey} id={`${model.devKey}`} className="space-y-10">
      <hr className="mb-20" />
      <div className="flex justify-between space-x-2">
        <div className="flex items-center space-x-2">
          <h2 className="text-2xl font-medium mb-0 break-all">
            {model.languageMap[currentProject.defaultLang]} ({model.devKey})
            <a href={`#${model.devKey}`} className="display-inline ml-2">
              <LinkOutlined />
            </a>
          </h2>
        </div>
        <Button
          type={'primary'}
          ghost
          className="flex-none"
          onClick={() => goContentsByModel(model)}
          title={t('editContents')}>
          {t('editContents')}
        </Button>
      </div>
      <div className="space-y-10">
        <div>
          <div className="flex justify-between items-center">
            <h4 className="font-medium">Interface</h4>
            <Tooltip title={t('clickToCopy')} className="cursor-help">
              <Button
                type={'text'}
                icon={<CopyOutlined />}
                onClick={() =>
                  copyInterface(`interface ${model.devKey} {
                    ${typeStr}
  }`)
                }
              />
            </Tooltip>
          </div>
          <div className="relative">
            <pre>
              <code className="language-javascript">
                {`export interface ${model.devKey} {
  ${typeStr}
}`}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
