import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ComponentOptionType, ComponentFieldType } from '@/types'
import { dateFormatsMoment } from '@/configs'

interface ComponentsTypeItemProps {
  componentType: ComponentFieldType
  componentOptionType: ComponentOptionType
  value: boolean | number | string | object
}

export const ComponentsOptionDesc = ({
  componentType,
  componentOptionType,
  value,
}: ComponentsTypeItemProps) => {
  const { t, i18n } = useTranslation()

  return (
    <>
      {/* 필수: 시작 */}
      {componentOptionType === 'required' && value ? (
        <li>{t('optionsType.required.desc')}</li>
      ) : (
        <></>
      )}
      {/* 필수: 끝 */}
      {/* 고유값: 시작 */}
      {componentOptionType === 'unique' && value ? (
        <li>{t('optionsType.unique.desc')}</li>
      ) : (
        <></>
      )}
      {/* 고유값: 끝 */}
      {/* 복수등록: 시작 */}
      {componentOptionType === 'multiple' && value ? (
        <li>{t('optionsType.multiple.' + componentType + '.desc')}</li>
      ) : (
        <></>
      )}
      {/* 복수등록: 끝 */}
      {/* 미디어 복수등록: 시작 */}
      {componentOptionType === 'multipleMedia' && value ? (
        <li>{t('optionsType.multipleMedia.desc')}</li>
      ) : (
        <></>
      )}
      {/* 미디어 복수등록: 끝 */}
      {/* HTML 태그 허용: 시작 */}
      {componentOptionType === 'allowHtml' && value ? (
        <li>{t('optionsType.allowHtml.desc')}</li>
      ) : (
        <></>
      )}
      {/* HTML 태그 허용: 끝 */}
      {/* 최소 텍스트 길이: 시작 */}
      {componentOptionType === 'minText' && value ? (
        <li>{t('optionsType.minText.name') + ': ' + value}</li>
      ) : (
        <></>
      )}
      {/* 최소 텍스트 길이: 끝 */}
      {/* 최대 텍스트 길이: 시작 */}
      {componentOptionType === 'maxText' && value ? (
        <li>{t('optionsType.maxText.name') + ': ' + value}</li>
      ) : (
        <></>
      )}
      {/* 최대 텍스트 길이: 끝 */}
      {/* 최소 숫자: 시작 */}
      {componentOptionType === 'min' && value ? (
        <li>{t('optionsType.min.name') + ': ' + value}</li>
      ) : (
        <></>
      )}
      {/* 최소 숫자: 끝 */}
      {/* 최대 숫자: 시작 */}
      {componentOptionType === 'max' && value ? (
        <li>{t('optionsType.max.name') + ': ' + value}</li>
      ) : (
        <></>
      )}
      {/* 최대 숫자: 끝 */}
      {/* 저장 가능 날짜 포맷: 시작 */}
      {componentOptionType === 'dateFormats' && value ? (
        <li>
          {t('optionsType.dateFormats.name')}:{' '}
          {dateFormatsMoment[value as string]}
        </li>
      ) : (
        <></>
      )}
      {/* 저장 가능 날짜 포맷: 끝 */}
    </>
  )
}
