import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '@/states/reducers'
import {
  Button,
  Table,
  Dropdown,
  Menu,
  Modal,
  Row,
  Col,
  Card,
  Select,
  message,
  Spin,
} from 'antd'
const { Option } = Select
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  deleteProject,
  getProjects,
  setCurrentProject,
  getProjectData,
  getProjectUsageData,
} from '@/states/actions/projects.actions'
import {
  getModelsData,
  setCurrentModel,
  setModelsList,
} from '@/states/actions/models.actions'
import { ProjectsListViewType } from './projects.list.viewType'
import moment from 'moment'
import { DEFAULT_CONTENTS_PAGE_SIZE } from '@/configs'
import { getSavedContentsSortKey } from '@/utils/helpers'
import { useAppDispatch } from '@/states/store'
import { getCategoryGroups } from '@/states/actions/categories.actions'

export const ProjectsList = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [viewType, setViewType] = useState('')
  const [sort, setSort] = useState<string>('editedAt')

  // State (Redux)
  const { projectsState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { currentProject, projectList } = projectsState
  const { projectListView } = layoutState

  // Effect
  useEffect(() => {
    setViewType(projectListView)
  }, [projectListView])

  // Table columns
  const tableCols = [
    {
      title: t('projectName'),
      key: 'name',
      render: (project) => (
        <div
          className={'flex items-center space-x-3 cursor-pointer'}
          onClick={() => enterProject(project)}>
          <div
            className={'w-6 h-6 bg-cover bg-gray-300 flex-none'}
            style={{
              backgroundImage: `url(${project?.image?.path})`,
            }}></div>
          <div className="flex space-x-2 items-center">{project.name}</div>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('plan'),
      key: 'plan',
      render: (project) => (
        <span className="border border-gray-300 rounded-sm px-1 font-normal text-xs">
          {project.price}
        </span>
      ),
      sorter: (a, b) => a.price.localeCompare(b.price),
    },
    {
      title: t('editedDate'),
      key: 'editedAt',
      render: (project) => (
        <>
          {project.date.editedAt
            ? moment(project.date.editedAt, 'YYYYMMDDHHmmss').fromNow()
            : '-'}
        </>
      ),
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.date.editedAt).unix() - moment(b.date.editedAt).unix(),
    },
    {
      title: t('createdDate'),
      key: 'createdAt',
      render: (project) => (
        <>
          {moment(project.date.createdAt, 'YYYYMMDDHHmmss').format(
            'YYYY-MM-DD'
          )}
        </>
      ),
      sorter: (a, b) =>
        moment(a.date.createdAt).unix() - moment(b.date.createdAt).unix(),
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      render: (project) =>
        project?.role === 'ADMIN' ? (
          <Dropdown overlay={projectMenus(project)} trigger={['click']}>
            <Button
              type={'text'}
              icon={<EllipsisOutlined></EllipsisOutlined>}
              title={t('more')}></Button>
          </Dropdown>
        ) : (
          <></>
        ),
    },
  ]

  /**
   * Table 정보 수정
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  const onTableChange = (pagination, filters, sorter, extra) => {
    setSort(sorter.field)
  }

  /**
   * 프로젝트 진입
   * @param project
   */
  const enterProject = (project, path = '') => {
    if (loading) return
    setLoading(true)

    getProjectData(project.uid)
      .then(async (res) => {
        const projectData = res.data.data

        getProjectUsageData(project.uid)
          .then(async (res) => {
            projectData.usage = res.data

            await dispatch(setCurrentProject(projectData))
            await dispatch(getCategoryGroups(project.uid))
            await getModelsData(project.uid)
              .then((res) => {
                dispatch(setModelsList(res.data.list))

                const modelInit = res.data.list.length ? res.data.list[0] : null

                if (modelInit && !path) {
                  dispatch(setCurrentModel(modelInit))

                  navigate(
                    `/projects/${project.uid}/contents/${
                      modelInit.id
                    }?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
                      modelInit.id
                    )}&q=`
                  )
                } else if (!path) {
                  navigate(`/projects/${project.uid}/builder`)
                } else {
                  navigate(`/projects/${project.uid}${path}`)
                }

                setLoading(false)
              })
              .catch((e) => {
                setLoading(false)
                dispatch(setModelsList([]))

                if (!path) {
                  navigate(`/projects/${project.uid}/builder`)
                } else {
                  navigate(`/projects/${project.uid}${path}`)
                }
              })
          })
          .catch((e) => {
            message.error(t('error.pageNotFound'))
            navigate(`/projects/`)
            setLoading(false)
          })
      })
      .catch((e) => {
        message.error(t('error.pageNotFound'))
        navigate(`/projects/`)
        setLoading(false)
      })
  }

  /**
   * 프로젝트 삭제 confirm
   * @param project
   */
  const onProjectDelete = (project) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteProjectTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteProjectDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteProject(project.uid)
            .then((res) => {
              dispatch(getProjects())
              message.success(t('deleteSuccess'))
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 프로젝트 액션 메뉴
   * @param project
   * @returns
   */
  const projectMenusItem = (project) => {
    return [
      {
        key: 'edit',
        label: t('editProject'),
        onClick: () => {
          enterProject(project, '/settings')
        },
      },
      {
        key: 'delete',
        label: t('deleteProject'),
        onClick: () => {
          onProjectDelete(project)
        },
      },
    ]
  }

  /**
   * 프로젝트 액션 툴
   * @param project
   */
  const projectMenus = (project) => (
    <Menu className={'w-48'} items={projectMenusItem(project)} />
  )

  /**
   * 프로젝트 카드
   * @param project
   * @constructor
   */
  const ProjectCardItem = (project) => {
    return (
      <Card
        cover={
          <div
            className={
              'w-full aspect-w-10 aspect-h-8 bg-cover bg-gray-300 cursor-pointer relative'
            }
            style={{
              backgroundImage: `url(${
                project?.image?.path ? project?.image?.path : ''
              })`,
            }}
            onClick={() => enterProject(project)}>
            <div className="bg-gray-400 rounded-sm px-1 w-max h-max font-normal text-xs absolute top-2 left-2">
              {project.price}
            </div>
          </div>
        }>
        <div className={'flex justify-between items-center'}>
          <div
            className={'overflow-hidden cursor-pointer'}
            onClick={() => enterProject(project)}>
            <p className={'leading-6 truncate mb-0'}>{project.name}</p>
            <p className={'text-xs text-gray-500 leading-5 truncate mb-0'}>
              {project.date.editedAt
                ? moment(project.date.editedAt, 'YYYYMMDDHHmmss').fromNow()
                : '-'}
            </p>
          </div>
          <div>
            {project?.role === 'ADMIN' ? (
              <Dropdown overlay={projectMenus(project)} trigger={['click']}>
                <Button
                  type={'text'}
                  icon={<EllipsisOutlined></EllipsisOutlined>}
                  title={t('more')}></Button>
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Card>
    )
  }

  return (
    <div className={'space-y-7 relative'}>
      {/* 프로젝트 목록 보기 옵션: 시작 */}
      <div className={'flex justify-end space-x-7'}>
        {viewType === 'card' ? (
          <div className={'flex items-center space-x-3'}>
            <span className={'text-xs text-gray-500 leading-5'}>Sort:</span>
            <div>
              <Select
                bordered={false}
                defaultValue={'editedAt'}
                style={{
                  width: 120,
                }}
                onChange={(val) => setSort(val)}>
                <Option value={'name'}>{t('projectName')}</Option>
                <Option value={'editedAt'}>{t('editedDate')}</Option>
                <Option value={'createdAt'}>{t('createdDate')}</Option>
              </Select>
            </div>
          </div>
        ) : (
          <></>
        )}
        <ProjectsListViewType />
      </div>
      {/* 프로젝트 목록 보기 옵션: 끝 */}
      {/* Card View: 시작 */}
      {viewType === 'card' ? (
        <Row gutter={24}>
          {projectList
            .sort(function (a, b) {
              return sort === 'name'
                ? a[sort].localeCompare(b[sort])
                : // @ts-ignore
                  moment(b.date[sort]).unix() - moment(a.date[sort]).unix()
            })
            .map((project) => (
              <Col key={project.uid} span={4} className="mb-6">
                {ProjectCardItem(project)}
              </Col>
            ))}
        </Row>
      ) : (
        <></>
      )}
      {/* Card View: 끝 */}
      {/* List View: 시작 */}
      {viewType === 'list' ? (
        <div>
          <Table
            // @ts-ignore
            columns={tableCols}
            dataSource={projectList}
            rowKey="uid"
            loading={loading}
            pagination={false}
            onChange={onTableChange}></Table>
        </div>
      ) : (
        <></>
      )}
      {/* List View: 끝 */}
      {viewType === 'card' && loading ? (
        <div
          className={`w-full h-full absolute top-0 right-0 flex justify-center items-center text-center`}>
          <Spin />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
