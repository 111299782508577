import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal, Button, Row, Col, Select, message } from 'antd'
import {
  CheckOutlined,
  CloseCircleOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { RootState } from '@/states/reducers'
import { setContentsFormModal } from '@/states/actions/modals.actions'
import { dateFormatReq, LAYOUT_MODAL_LG_WIDTH } from '@/configs'
import { AlertStatus } from '@/components/common/common.alert'
import {
  ComponentInterface,
  CellInterface,
  LanguageMap,
  LanguagesAvailable,
  ProjectInterface,
  CellValidationInterface,
} from '@/types'
import { ContentsFormComponent } from './'
import {
  createContents,
  updateContents,
  updateContentsItem,
  setContentsReqComponents,
  deleteContents,
  getContentsItem,
  addContentsList,
  deleteContentsList,
} from '@/states/actions/contents.actions'
import { checkCellValidation } from '@/utils/helpers'
import moment from 'moment'
import { getProjectUsage } from '@/states/actions/projects.actions'
import { useAppDispatch } from '@/states/store'

const initialContentsFormValues = {}

interface ContentsChangeProps {
  onChangeContents?: () => void
}

export const ContentsFormModal = ({
  onChangeContents,
}: ContentsChangeProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const [, updateState] = React.useState<{} | undefined>()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  // Params
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,

      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { contentsFormModal, contentsInfo } = modalsState
  const {
    currentProject,
    currentModel,
    reqComponents,
    contentsPagination,
    contentsSort,
    contentsListReload,
    contentsListLoading,
    allContentsList,
  } = projectsState

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [flatComponents, setFlatComponents] = useState<ComponentInterface[]>([])
  const [currentLang, setCurrentLang] = useState<LanguagesAvailable>('KO')
  const [langs, setLangs] = useState<
    { label: string; value: LanguagesAvailable }[]
  >([])

  // Effect
  useEffect(() => {
    if (currentProject) {
      setCurrentLang(currentProject.defaultLang)
      setLangs(
        currentProject.languageList.map((l) => {
          return { label: t('lang.' + l.toLocaleLowerCase()), value: l }
        })
      )
    }
  }, [currentProject])

  useEffect(() => {
    if (contentsFormModal) {
      if (!contentsInfo) {
        formikContentsForm.validateForm()
      }

      generateReqComponents(true)
    } else {
      resetForm()
    }
  }, [contentsFormModal])

  useEffect(() => {
    if (currentModel) {
      flattenComponents()
    }
  }, [currentModel])

  useEffect(() => {
    if (flatComponents && flatComponents.length) {
      // generateReqComponents()
    }
  }, [flatComponents])

  /* useEffect(() => {
    if (contentsInfo && currentProject) {
    }
  }, [contentsInfo]) */

  // Validation
  const validationcontentsFormSchema = Yup.object().shape({})

  // Formik
  const formikContentsForm = useFormik({
    initialValues: initialContentsFormValues,
    validationSchema: validationcontentsFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!currentModel || !currentProject) return false

      // 필수 입력 확인
      let validationErrorArr: CellValidationInterface[] = []
      reqComponents.forEach((rc) => {
        validationErrorArr = [
          ...validationErrorArr,
          ...checkCellValidation(
            rc,
            currentProject as ProjectInterface,
            contentsInfo,
            allContentsList
          ),
        ]
      })

      if (validationErrorArr.length) {
        message.warning({
          content: (
            <ul className="text-left list-disc pl-4 mb-0">
              {validationErrorArr.map((error, eIdx) => (
                <li key={eIdx}>
                  {t('error.' + error.errorType, { field: error.name })}
                </li>
              ))}
            </ul>
          ),
        })

        return false
      }

      setLoading(true)

      // Sanitize
      reqComponents.forEach((cell) => {
        if (
          cell.component &&
          (cell.component.type === 'SINGLE_LINE_TEXT' ||
            cell.component.type === 'LONG_LINE_TEXT') &&
          cell.component.option &&
          !cell.component.option.allowHtml
        ) {
          currentProject?.languageList.forEach((lang) => {
            if (cell && cell.languageMap && cell.languageMap[lang]) {
              cell.languageMap[lang] = (cell.languageMap[lang] as string)
                .trim()
                .normalize('NFC')
                .replace(/(<([^>]+)>)/gi, '')
            }
          })
        }

        if (
          cell.component &&
          (cell.component.type === 'PASSWORD' ||
            cell.component.type === 'EMAIL') &&
          cell.value
        ) {
          cell.value = (cell.value as string)
            .trim()
            .normalize('NFC')
            .replace(/(<([^>]+)>)/gi, '')
        }

        if (
          cell.component?.type === 'DATE' &&
          cell.value &&
          typeof cell.value === 'string'
        ) {
          if (cell.option?.dateFormats === 'year') {
            cell.value = moment(cell.value, 'YYYY').format(dateFormatReq)
          } else if (cell.option?.dateFormats === 'month') {
            cell.value = moment(cell.value, 'YYYY-MM').format(dateFormatReq)
          } else if (cell.option?.dateFormats === 'date') {
            cell.value = moment(cell.value, 'YYYY-MM-DD').format(dateFormatReq)
          }
        }
      })

      const req = {
        order: null,
        uid: '',
        cellList: reqComponents,
      }

      if (!contentsInfo) {
        createContents(currentProject?.uid, currentModel?.id, req)
          .then((res) => {
            message.success(t('saveSuccess'))

            getContentsItem(
              currentProject?.uid,
              currentModel?.id,
              res.data.data
            ).then((res) => {
              dispatch(addContentsList(res.data.data))
              dispatch(getProjectUsage(currentProject?.uid as string))
            })

            navigate(
              `/projects/${currentProject?.uid}/contents/${
                currentModel?.id
              }?page=${contentsPagination.current}&size=${
                contentsPagination.pageSize
              }&sort=${contentsSort}&q=${searchParams.get('q')}`
            )
          })
          .catch((e) => {
            message.error(e.response.data.error)
            setLoading(false)
          })
      } else {
        if (contentsInfo.uid) {
          req.uid = contentsInfo.uid
        }

        updateContents(
          currentProject?.uid,
          currentModel?.id,
          contentsInfo.uid,
          req
        )
          .then((res) => {
            getContentsItem(
              currentProject?.uid,
              currentModel?.id,
              contentsInfo.uid
            ).then((res) => {
              message.success(t('saveSuccess'))

              dispatch(updateContentsItem(res.data.data))

              navigate(
                `/projects/${currentProject?.uid}/contents/${
                  currentModel?.id
                }?page=${contentsPagination.current}&size=${
                  contentsPagination.pageSize
                }&sort=${contentsSort}&q=${
                  searchParams.get('q') ? searchParams.get('q') : ''
                }`
              )
            })
          })
          .catch((e) => {
            message.error(e.response.data.error)
            setLoading(false)
          })
      }
    },
  })

  /**
   * 컴포넌트 depth 제거
   */
  const flattenComponents = () => {
    if (currentModel) {
      // Component 평탄화
      const flattedComponentsArr: ComponentInterface[] = []
      var flatComponents = (compList) => {
        compList.forEach((comp) => {
          if (comp.childList) {
            flattedComponentsArr.push(comp)
            flatComponents(comp.childList)
          } else flattedComponentsArr.push(comp)
        })
      }

      flatComponents(currentModel.componentList)
      setFlatComponents(flattedComponentsArr)
    }
  }

  /**
   * 컴포넌트 depth 제거
   */
  const generateReqComponents = (isScroll = false) => {
    // Request Cell 생성
    const cellArr: CellInterface[] = []
    flatComponents
      .filter((comp) => comp.type !== 'BLOCK')
      .forEach((comp) => {
        const oldCell =
          contentsInfo &&
          contentsInfo?.cellList?.find(
            (cell) => cell?.component?.id === comp.id
          )

        // Language Map
        const languageMap: LanguageMap = {}
        currentProject?.languageList.forEach((lang) => {
          languageMap[lang] =
            oldCell && oldCell.languageMap ? oldCell.languageMap[lang] : ''
        })

        const cellInfo: CellInterface = {
          componentId: comp.id as number,
          component: comp,
          type: comp.type,
          option: comp.option,
          value: oldCell && oldCell.value ? oldCell.value : null,
          selectorList:
            oldCell && oldCell.selectorList && oldCell.selectorList.length
              ? oldCell.selectorList
              : [],
          selectorIdList:
            oldCell && oldCell.selectorList && oldCell.selectorList.length
              ? oldCell.selectorList.map((s) => s.id as number)
              : [],
          mediaIdList:
            oldCell && oldCell.mediaList
              ? oldCell.mediaList.map((m) => m.id)
              : [],
          mediaList: oldCell && oldCell.mediaList ? oldCell.mediaList : [],
          relationUidList:
            oldCell && oldCell.relationList
              ? oldCell.relationList.map((m) => m.uid)
              : [],
          relationList:
            oldCell && oldCell.relationList ? oldCell.relationList : [],
          languageMap: languageMap,
        }

        if (oldCell) {
          cellInfo.uid = oldCell.uid
        }

        cellArr.push(cellInfo)
      })

    dispatch(setContentsReqComponents(cellArr))

    if (isScroll) {
      setTimeout(() => {
        const modalForm = document.getElementById(
          'contents-modal-form'
        ) as HTMLElement

        if (modalForm) {
          if (searchParams.get('component')) {
            const contentsComponent = document.getElementById(
              `contents-component-${searchParams.get('component')}`
            )

            setTimeout(() => {
              if (contentsComponent) {
                modalForm.scrollTop =
                  contentsComponent.getBoundingClientRect().top -
                  modalForm.getBoundingClientRect().top
              }
            })
          } else {
            modalForm.scrollTop = 0
          }
        }
      })
    }
  }

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikContentsForm.resetForm()
    setLoading(false)
    // generateReqComponents()
    dispatch(setContentsReqComponents([]))
  }

  /**
   * 셀 변경
   * @param componentId
   * @param key
   * @param value
   * @param lang
   */
  const onHandleChangeCell = (componentId, key, value, lang) => {
    const updatedReqComponents = JSON.parse(JSON.stringify(reqComponents))
    const updatedReqComponent = updatedReqComponents.find(
      (rc) => rc.componentId === componentId
    )
    const idx = updatedReqComponents.indexOf(updatedReqComponent)
    if (!updatedReqComponent) return

    if (lang) {
      updatedReqComponent[key][lang] = value
    } else if (typeof updatedReqComponent[key] !== 'undefined') {
      updatedReqComponent[key] = value
    }

    if (
      updatedReqComponent.type === 'DATE' &&
      updatedReqComponent.option?.dateFormats === 'time'
    ) {
      updatedReqComponent[key] = moment(value, 'HH:mm:ss').format(dateFormatReq)
    }

    if (key === 'mediaList') {
      updatedReqComponent.mediaIdList = value.map((m) => m.id)
    } else if (key === 'relationList') {
      updatedReqComponent.relationUidList = value.map((m) => m.uid)
    }

    updatedReqComponents[idx] = updatedReqComponent
    dispatch(setContentsReqComponents(updatedReqComponents))
  }

  /**
   * 모델 수정폼 열기
   * @param model
   */
  const onModelEdit = () => {
    if (
      currentModel &&
      currentProject &&
      confirm(
        t('confirmEditModel', {
          model: currentModel.languageMap[currentProject?.defaultLang],
        })
      )
    ) {
      navigate(`/projects/${currentProject.uid}/builder/${currentModel.id}`)

      dispatch(setContentsFormModal(false))
    }
  }

  /**
   * 모달 닫기
   */
  const onModelFormClose = (isConfirm = false) => {
    if (isConfirm && !confirm(t('confirmClose'))) {
      return false
    }

    navigate(
      `/projects/${currentProject?.uid}/contents/${currentModel?.id}?page=${
        contentsPagination.current
      }&size=${contentsPagination.pageSize}&sort=${contentsSort}&q=${
        searchParams.get('q') ? searchParams.get('q') : ''
      }`
    )
  }

  /**
   * 콘텐츠 삭제
   * @param contentsToBeDeleted
   */
  const onContentsDelete = (contentsToBeDeleted) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteContentsTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteContentsDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteContents(
            currentProject?.uid,
            currentModel?.id,
            contentsToBeDeleted.uid
          )
            .then((res) => {
              message.success(t('deleteSuccess'))
              dispatch(deleteContentsList([contentsToBeDeleted.uid]))
              dispatch(getProjectUsage(currentProject?.uid as string))
              onModelFormClose()
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  return currentProject && currentModel ? (
    <>
      {/* 콘텐츠 모달: 시작 */}
      <Modal
        width={LAYOUT_MODAL_LG_WIDTH}
        centered={true}
        maskClosable={false}
        closeIcon={<CloseCircleOutlined title={t('close')} />}
        open={contentsFormModal}
        bodyStyle={{
          backgroundColor: '#FAFAFA',
        }}
        onCancel={() => onModelFormClose(true)}
        title={
          <div className="flex items-center space-x-2">
            <span>{currentModel.languageMap[currentProject.defaultLang]}</span>
            <span className="flex items-center">
              <RightOutlined className="text-xs"></RightOutlined>
            </span>
            <span>{contentsInfo ? t('editContents') : t('addContents')}</span>
          </div>
        }
        footer={[
          <div key={'footer'} className={'flex justify-between items-center'}>
            <div>
              <Button
                type="primary"
                ghost
                disabled={loading || contentsListReload || contentsListLoading}
                onClick={() => onModelFormClose(true)}>
                {t('cancel')}
              </Button>
              {contentsInfo && currentProject.role !== 'VIEWER' ? (
                <Button
                  type="text"
                  danger
                  disabled={
                    loading || contentsListReload || contentsListLoading
                  }
                  onClick={() => onContentsDelete(contentsInfo)}>
                  {t('delete')}
                </Button>
              ) : (
                <></>
              )}
            </div>
            <div>
              {currentProject.role !== 'VIEWER' ? (
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  className="w-52"
                  onClick={() => formikContentsForm.submitForm()}
                  disabled={
                    loading || contentsListReload || contentsListLoading
                  }
                  loading={loading}>
                  {t('save')}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>,
        ]}>
        {contentsFormModal ? (
          <form
            onSubmit={formikContentsForm.handleSubmit}
            method="POST"
            autoComplete="off">
            <AlertStatus
              status={formikContentsForm.status}
              onClick={() => formikContentsForm.setStatus(null)}></AlertStatus>
            <div className={'space-y-6'}>
              <Row gutter={48}>
                <Col
                  id="contents-modal-form"
                  span={18}
                  className={'overflow-y-auto'}
                  style={{
                    height: 'calc(100vh - 220px)',
                  }}>
                  <div>
                    <Row gutter={24} className="space-y-6">
                      {currentModel.componentList.map((comp) => (
                        <Col
                          span={
                            comp?.option?.colSpan ? comp.option.colSpan : 24
                          }
                          key={comp.id}>
                          <ContentsFormComponent
                            onCellChange={onHandleChangeCell}
                            cell={
                              reqComponents
                                ? reqComponents.find(
                                    (cell) => cell?.componentId === comp.id
                                  )
                                : null
                            }
                            flattenCells={reqComponents}
                            component={comp}
                            curLang={currentLang}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
                <Col span={6} className="space-y-3">
                  {/* 언어 선택: 시작 */}
                  <div className="bg-white rounded shadow p-3 text-sm">
                    <p className="mb-1">{t('languages')}</p>
                    <div className="space-y-3">
                      <Select
                        defaultValue={currentProject.defaultLang}
                        className="w-full"
                        onChange={(val) => setCurrentLang(val)}>
                        {langs.map((lang) => (
                          <Select.Option key={lang.value}>
                            {lang.label}
                          </Select.Option>
                        ))}
                      </Select>
                      {contentsInfo && contentsInfo.date ? (
                        <div className="space-y-2">
                          <div>
                            <p className="mb-1">{t('createdDate')}</p>
                            <p className="mb-0">
                              {contentsInfo.date?.createdAt
                                ? moment(
                                    contentsInfo.date?.createdAt,
                                    'YYYYMMDDHHmmss'
                                  ).format('YYYY-MM-DD HH:mm')
                                : moment(
                                    contentsInfo.date?.createdAt,
                                    'YYYYMMDDHHmmss'
                                  ).format('YYYY-MM-DD HH:mm')}
                            </p>
                          </div>
                          <div>
                            <p className="mb-1">{t('lastEditedDate')}</p>
                            <p className="mb-0">
                              {contentsInfo.date?.editedAt
                                ? moment(
                                    contentsInfo.date?.editedAt,
                                    'YYYYMMDDHHmmss'
                                  ).format('YYYY-MM-DD HH:mm')
                                : moment(
                                    contentsInfo.date?.createdAt,
                                    'YYYYMMDDHHmmss'
                                  ).format('YYYY-MM-DD HH:mm')}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {/* 언어 선택: 끝 */}
                  {/* 추가 기능 버튼: 시작*/}
                  <div className="space-y-2">
                    <Button block disabled>
                      {t('notes')}
                    </Button>
                    {currentProject.role !== 'VIEWER' ? (
                      <Button block onClick={() => onModelEdit()}>
                        {t('editModel')}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* 추가 기능 버튼: 끝 */}
                </Col>
              </Row>
            </div>
          </form>
        ) : (
          <></>
        )}
      </Modal>
      {/* 콘텐츠 모달: 끝 */}
    </>
  ) : (
    <></>
  )
}
