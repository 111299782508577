import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { Layout, Menu, message, Tooltip } from 'antd'
import {
  FileTextOutlined,
  FileImageOutlined,
  BuildOutlined,
  SettingOutlined,
  CodeSandboxOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons'
import { RootState } from '@/states/reducers'
const { Sider } = Layout
import {
  DEFAULT_CONTENTS_PAGE_SIZE,
  GNB_TOGGLE_KEY,
  LAYOUT_GNB_WIDTH,
  LAYOUT_GNB_COLLAPSE_WIDTH,
} from '@/configs'
import { ProjectsLogo } from '../projects/projects.logo'
import { AuthProfile } from '../auth/auth.profile'
import { setCurrentModel } from '@/states/actions/models.actions'
import { getSavedContentsSortKey } from '@/utils/helpers'
import { toggleGnb } from '@/states/actions/layout.actions'
import { resetContentsList } from '@/states/actions/contents.actions'
import { ProjectsUsageBar } from '@/components/projects/project.usage.bar'
import { useAppDispatch } from '@/states/store'

export const LayoutGnbProject = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  // Params
  let { projectUid } = useParams()

  // State (Redux)
  const { layoutState, projectsState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { gnb } = layoutState
  const {
    currentProject,
    modelList,
    currentModel,
    tmpModel,
    contentsListLoading,
  } = projectsState

  // Effect
  useEffect(() => {
    // GNB 닫기 설정
    setGnbCollapse()
  }, [])

  /**
   * 링크 연결
   * @param url
   */
  const goLink = (url) => {
    if (
      tmpModel &&
      JSON.stringify(currentModel) !== JSON.stringify(tmpModel) &&
      !confirm(t('confirmNotSaveRecently'))
    ) {
      return false
    }

    navigate(url)
  }

  /**
   * 콘텐츠 진입
   * @param model
   * @returns
   */
  const goContents = () => {
    const model = modelList.length ? modelList[0] : null

    if (
      tmpModel &&
      JSON.stringify(currentModel) !== JSON.stringify(tmpModel) &&
      !confirm(t('confirmNotSaveRecently'))
    ) {
      return false
    }

    if (!model) {
      message.info(t('modelNeed'))
      return
    }

    dispatch(setCurrentModel(model))
    dispatch(resetContentsList())

    navigate(
      `/projects/${currentProject?.uid}/contents/${
        model.id
      }?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
        model.id
      )}&q=`
    )
  }

  /**
   * 컴포넌트 타입 버튼 Toggle
   */
  const toggleGnbCollapse = () => {
    dispatch(toggleGnb(!gnb))

    Cookies.set(GNB_TOGGLE_KEY, !gnb ? '1' : '0', {
      expires: 86400,
    })
  }

  /**
   * 프로젝트 메뉴
   */
  const projectMenus = [
    {
      key: 'contents',
      label: t('contents'),
      title: t('contents'),
      icon: gnb ? (
        <FileTextOutlined />
      ) : (
        <Tooltip title={t('contents')} placement="right">
          <FileTextOutlined />
        </Tooltip>
      ),
      onClick: () => {
        goContents()
      },
      disabled: contentsListLoading,
    },
    {
      key: 'mediaContents',
      label: t('mediaContents'),
      title: t('mediaContents'),
      icon: gnb ? (
        <FileImageOutlined />
      ) : (
        <Tooltip title={t('mediaContents')} placement="right">
          <FileImageOutlined />
        </Tooltip>
      ),
      onClick: () => {
        goLink(`/projects/${projectUid}/media`)
      },
      disabled: contentsListLoading,
    },
  ]

  /**
   * 프로젝트 설정 메뉴
   */
  const projectSettingsMenus = [
    {
      key: 'contentsBuilder',
      icon: gnb ? (
        <BuildOutlined />
      ) : (
        <Tooltip title={t('contentsBuilder')} placement="right">
          <BuildOutlined />
        </Tooltip>
      ),
      label: t('contentsBuilder'),
      onClick: () => {
        goLink(`/projects/${projectUid}/builder`)
      },
      disabled: contentsListLoading,
    },
    {
      key: 'settings',
      icon: gnb ? (
        <SettingOutlined />
      ) : (
        <Tooltip title={t('settings')} placement="right">
          <SettingOutlined />
        </Tooltip>
      ),
      label: t('settings'),
      onClick: () => {
        goLink(`/projects/${projectUid}/settings`)
      },
      disabled: contentsListLoading,
    },
    {
      key: 'developers',
      icon: gnb ? (
        <CodeSandboxOutlined />
      ) : (
        <Tooltip title={t('developers')} placement="right">
          <CodeSandboxOutlined />
        </Tooltip>
      ),
      label: t('developers'),
      onClick: () => {
        goLink(`/projects/${projectUid}/developers/apps`)
      },
      disabled: contentsListLoading,
    },
  ]

  /**
   * 화면 모드 설정
   */
  const setGnbCollapse = () => {
    const gnbCookie = Cookies.get(GNB_TOGGLE_KEY)

    if (gnbCookie && gnbCookie === '0') {
      dispatch(toggleGnb(false))
    }
  }

  return (
    <Sider
      theme={'light'}
      width={gnb ? LAYOUT_GNB_WIDTH : LAYOUT_GNB_COLLAPSE_WIDTH}
      className={`${
        gnb ? 'overflow-hidden overflow-y-auto' : 'overflow-x-visible'
      } h-screen`}>
      <ProjectsLogo onClick={goContents} />
      <div className={'py-3 space-y-4'}>
        <div>
          <Menu
            theme="light"
            selectedKeys={[
              location.pathname.includes(`/projects/${projectUid}/contents`)
                ? 'contents'
                : '',
              location.pathname.includes(`/projects/${projectUid}/media`)
                ? 'mediaContents'
                : '',
            ]}
            mode="inline"
            items={projectMenus}
          />
        </div>
        <div>
          {gnb ? (
            <h4
              className={
                'py-3 px-6 text-xs font-bold uppercase leading-5 mb-0'
              }>
              {t('settings')}
            </h4>
          ) : (
            <hr />
          )}
          <Menu
            theme="light"
            defaultSelectedKeys={[]}
            selectable={false}
            selectedKeys={[
              location.pathname.includes(`/projects/${projectUid}/settings`)
                ? 'settings'
                : '',
              location.pathname.includes(`/projects/${projectUid}/builder`)
                ? 'contentsBuilder'
                : '',
              location.pathname.includes(`/projects/${projectUid}/developers`)
                ? 'developers'
                : '',
            ]}
            mode="inline"
            items={projectSettingsMenus}
          />
        </div>
      </div>
      <hr />
      {/* 사용량: 시작 */}
      {process.env.REACT_APP_USAGE_HIDE !== '1' ? (
        <div className={gnb ? '' : 'hidden'}>
          <div className="py-6 px-6 flex justify-between items-center">
            <h4 className={' text-xs font-bold uppercase leading-5 mb-0'}>
              {t('usage')}{' '}
              <span className="ml-1 border border-gray-300 rounded-sm px-1 font-normal">
                {currentProject?.usage?.type}
              </span>
            </h4>
            <button
              onClick={() =>
                navigate(`/projects/${currentProject?.uid}/settings/usage`)
              }
              type="button"
              disabled={contentsListLoading}
              className="text-xs text-blue-500">
              {t('more')}
            </button>
          </div>
          <div className="space-y-4 text-xs px-6">
            <ProjectsUsageBar
              usage={currentProject?.usage?.content}
              label={t('noContents')}
              size="small"
            />
            <ProjectsUsageBar
              usage={currentProject?.usage?.storage}
              label={t('mediaStorage')}
              size="small"
              isFile
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* 사용량: 끝 */}
      <AuthProfile></AuthProfile>
      <div
        className={`cursor-pointer absolute  right-0 ${
          gnb ? 'bottom-12 w-14 h-14' : 'bottom-10 w-16 h-12'
        }  flex justify-center items-center`}
        onClick={toggleGnbCollapse}
        title={gnb ? t('collapse') : t('open')}>
        {!gnb ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
      </div>
    </Sider>
  )
}
