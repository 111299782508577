import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal, Button, Tooltip, Input, Row, Col, message } from 'antd'
import {
  CheckOutlined,
  CloseCircleOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { RootState } from '@/states/reducers'
import { setProjectAccessFormModal } from '@/states/actions/modals.actions'
import { LAYOUT_MODAL_WIDTH } from '@/configs'
import { AlertStatus } from '@/components/common/common.alert'
import {
  createProjectAccess,
  generateProjectAccessToken,
  getProjectAccess,
  updateProjectAccess,
} from '@/states/actions/projects.actions'
import { copyStr } from '@/utils/helpers'
import { useAppDispatch } from '@/states/store'

const initialprojectAccessFormValues = {
  name: '',
  description: '',
  token: '',
  hosts: '',
}

export const ProjectsApiKeyModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { projectAccessFormModal, projectAccessInfo } = modalsState
  const { currentProject } = projectsState

  // State
  const [loading, setLoading] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    if (projectAccessFormModal) {
      setTimeout(() => {
        document.getElementById('projectAccessName')?.focus()
      })
    } else {
      resetForm()
    }
  }, [projectAccessFormModal])

  useEffect(() => {
    if (projectAccessInfo) {
      formikProjectAccessKey.setFieldValue('name', projectAccessInfo.name)
      formikProjectAccessKey.setFieldValue(
        'description',
        projectAccessInfo.description
      )
      formikProjectAccessKey.setFieldValue('token', projectAccessInfo.token)
      formikProjectAccessKey.setFieldValue(
        'hosts',
        projectAccessInfo.list?.join('\n')
      )
    }
  }, [projectAccessInfo])

  // Validation
  const validationprojectAccessFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    hosts: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikProjectAccessKey = useFormik({
    initialValues: initialprojectAccessFormValues,
    validationSchema: validationprojectAccessFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (currentProject?.role !== 'ADMIN') {
        message.error(t('error.noAccessGranted'))
        return false
      }

      try {
        if (currentProject) {
          setStatus(null)
          setLoading(true)

          // API 추가

          const req = {
            name: values.name,
            description: values.description,
            list: values.hosts.split('\n'),
          }

          // URL Validation check
          if (
            !req.list.find((hostItem) => hostItem) ||
            req.list.find((hostItem) => !isValidHttpUrl(hostItem))
          ) {
            message.error(t('error.validUrl'))
            setLoading(false)
            return false
          }

          if (projectAccessInfo) {
            updateProjectAccess(currentProject.uid, projectAccessInfo.id, req)
              .then((res) => {
                message.success(t('saveSuccess'))
                dispatch(setProjectAccessFormModal(false))
                setLoading(false)
                dispatch(getProjectAccess(currentProject?.uid as string))
              })
              .catch((e) => {
                message.error(e.response.data.error)
                setLoading(false)
              })
          } else {
            createProjectAccess(currentProject.uid, req)
              .then((res) => {
                generateProjectAccessToken(currentProject.uid, res.data.data)
                  .then((res) => {
                    message.success(t('saveSuccess'))
                    dispatch(setProjectAccessFormModal(false))
                    setLoading(false)
                    dispatch(getProjectAccess(currentProject?.uid as string))
                  })
                  .catch((e) => {
                    message.error(e.response.data.error)
                    setLoading(false)
                  })
              })
              .catch((e) => {
                message.error(e.response.data.error)
                setLoading(false)
              })
          }
        }
      } catch (e) {
        setLoading(false)
        setSubmitting(false)
        // @ts-ignore
        setStatus(e.response.data.error)
      }
    },
  })

  /**
   * 올바른 URL 확인
   * @param string
   * @returns
   */
  const isValidHttpUrl = (string) => {
    if (string === '*') return true

    let url

    try {
      url = new URL(string)
    } catch (e) {
      return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
  }

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikProjectAccessKey.resetForm()
    setLoading(false)

    setTimeout(() => {
      document.getElementById('projectAccessName')?.focus()
    })
  }

  /**
   * 액세스 토큰 복사
   * @param str
   */
  const copyToken = (str) => {
    if (copyStr(str)) {
      message.success(t('copied'))
    }
  }

  return (
    <Modal
      width={LAYOUT_MODAL_WIDTH}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={projectAccessFormModal}
      maskClosable={false}
      onCancel={() => dispatch(setProjectAccessFormModal(false))}
      title={projectAccessInfo ? t('editAccess') : t('createAccess')}
      footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => dispatch(setProjectAccessFormModal(false))}>
              {t('cancel')}
            </Button>
          </div>
          {currentProject?.role === 'ADMIN' ? (
            <div>
              <Button
                type={'primary'}
                icon={<CheckOutlined />}
                onClick={() => formikProjectAccessKey.submitForm()}
                disabled={loading}
                loading={loading}>
                {t('save')}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>,
      ]}>
      <>
        <form onSubmit={formikProjectAccessKey.handleSubmit} method="POST">
          <AlertStatus
            status={formikProjectAccessKey.status}
            onClick={() =>
              formikProjectAccessKey.setStatus(null)
            }></AlertStatus>
          <div className={'space-y-0'}>
            <Row gutter={0}>
              <Col span={24}>
                <div className="space-y-4">
                  <div className="block">
                    <label htmlFor="projectAccessName" className="">
                      <div className={'mb-2'}>
                        {t('name')} <span className="text-red-500">*</span>
                      </div>
                      <Input
                        id={'projectAccessName'}
                        name="name"
                        type={'text'}
                        onChange={formikProjectAccessKey.handleChange}
                        value={formikProjectAccessKey.values.name}
                        readOnly={currentProject?.role !== 'ADMIN'}
                      />
                    </label>
                    {formikProjectAccessKey.touched.name &&
                    formikProjectAccessKey.errors.name ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikProjectAccessKey.errors.name}
                      </p>
                    ) : null}
                  </div>
                  <div className="block">
                    <label htmlFor="projectAccessDescription" className="">
                      <div className={'mb-2'}>
                        {t('description')}{' '}
                        <span className={'text-gray-500 ml-1'}>(optional)</span>
                      </div>
                      <Input.TextArea
                        rows={2}
                        id={'projectAccessDescription'}
                        name="description"
                        onChange={formikProjectAccessKey.handleChange}
                        value={formikProjectAccessKey.values.description}
                        readOnly={currentProject?.role !== 'ADMIN'}
                      />
                    </label>
                  </div>
                  {projectAccessInfo && projectAccessInfo.token ? (
                    <div className="block">
                      <label htmlFor="projecttoken" className="">
                        <div className={'mb-2'}>
                          {t('apiAccessToken')}{' '}
                          <span className="text-red-500">*</span>
                        </div>
                        <Input.Group compact>
                          <Input.Password
                            id={'projecttoken'}
                            name="token"
                            type={'password'}
                            readOnly
                            onChange={formikProjectAccessKey.handleChange}
                            value={formikProjectAccessKey.values.token}
                            style={{
                              width: `calc(100% - 2rem)`,
                            }}
                          />
                          <Tooltip title={t('copy')}>
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToken(formikProjectAccessKey.values.token)
                              }
                            />
                          </Tooltip>
                        </Input.Group>
                      </label>
                      {formikProjectAccessKey.touched.token &&
                      formikProjectAccessKey.errors.token ? (
                        <p className="my-1 text-xs text-red-500">
                          {formikProjectAccessKey.errors.token}
                        </p>
                      ) : null}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="block">
                    <label htmlFor="projectAccessHosts" className="">
                      <div className="mb-2">
                        <div className={'mb-2'}>
                          {t('allowedDomain')}{' '}
                          <span className="text-red-500">*</span>
                        </div>
                        <p className="text-gray-600 mb-0">
                          {t('domainAddDesc')}
                        </p>
                        <p className="text-gray-600 mb-0">{t('domainAddEx')}</p>
                        <p className="text-gray-600 mb-0">
                          {t('domainAddExAll')}
                        </p>
                      </div>
                      <Input.TextArea
                        rows={5}
                        id={'projectAccessHosts'}
                        name="hosts"
                        onChange={formikProjectAccessKey.handleChange}
                        value={formikProjectAccessKey.values.hosts}
                        readOnly={currentProject?.role !== 'ADMIN'}
                      />
                    </label>
                    {formikProjectAccessKey.touched.hosts &&
                    formikProjectAccessKey.errors.hosts ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikProjectAccessKey.errors.hosts}
                      </p>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </>
    </Modal>
  )
}
