import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Button, Empty, Spin } from 'antd'
import { LayoutHome } from '@/components/layout'
import { RootState } from '@/states/reducers'
import { getProjects } from '@/states/actions/projects.actions'
import { setProjectFormModal } from '@/states/actions/modals.actions'
import { ProjectsList } from '@/components/projects'
import { useAppDispatch } from '@/states/store'

const ProjectsIndex = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { projectsListInit, projectList } = projectsState
  const { init } = authState

  // Effect
  useEffect(() => {
    if (init) {
      dispatch(getProjects())
    }
  }, [init])

  return (
    <LayoutHome>
      <Helmet>
        <title>
          {t('projects')} · {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      {projectsListInit ? (
        <div className={'pt-4 pb-52 px-6'}>
          <div className="flex justify-between">
            <div></div>
            <div>
              <Button
                type={'primary'}
                htmlType={'button'}
                onClick={() => dispatch(setProjectFormModal(true))}>
                {t('createProject')}
              </Button>
            </div>
          </div>
          {projectList.length ? (
            <div className={'pt-10'}>
              {/* 프로젝트 목록: 시작 */}
              <ProjectsList />
              {/* 프로젝트 목록: 끝 */}
            </div>
          ) : (
            <div
              className={'gap-7 flex justify-center items-center'}
              style={{
                height: 'calc(100vh - 6rem)',
              }}>
              <div>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={false}
                />
                <div className={'flex justify-center'}>
                  <Button
                    type={'primary'}
                    htmlType={'button'}
                    onClick={() => dispatch(setProjectFormModal(true))}>
                    {t('createProject')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={'h-screen flex justify-center items-center'}>
          <Spin />
        </div>
      )}
    </LayoutHome>
  )
}

export default ProjectsIndex
