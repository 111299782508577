import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Button,
  Spin,
  Empty,
  Menu,
  Dropdown,
  Modal,
  message,
  Tooltip,
} from 'antd'
import axios from 'axios'
import {
  SaveOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
  InfoCircleOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '@/states/reducers'
import {
  DEFAULT_CONTENTS_PAGE_SIZE,
  LAYOUT_OKAY_COLOR,
  LAYOUT_WARNING_COLOR,
} from '@/configs'
import { LayoutProjects } from '@/components/layout'
import { ProjectModelUsageInterface } from '@/types'
import { setModelFormModal } from '@/states/actions/modals.actions'
import { ModelsFormModal } from '@/components/models'
import {
  ComponentsFormModal,
  ComponentsItem,
  ComponentsAdd,
} from '@/components/components'
import { LAYOUT_COMPONENT_TYPE_WIDTH } from '@/configs'
import {
  getModels,
  updateModel,
  deleteModel,
  getModelData,
  setTmpModel,
  createModel,
  setCurrentModel,
} from '@/states/actions/models.actions'

import {
  setComponentsRequestBody,
  flattenComponents,
  getSavedContentsSortKey,
} from '@/utils/helpers'
import {
  createSelector,
  updateSelector,
} from '@/states/actions/components.actions'
import { resetContentsList } from '@/states/actions/contents.actions'
import {
  getProjectUsage,
  getProjectUsageData,
  setCurrentProject,
} from '@/states/actions/projects.actions'
import { useNavigate } from 'react-router'
import { useAppDispatch } from '@/states/store'
import { CategoriesFormModal } from '@/components/categories'

const ProjectsContentsBuilder = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, modelListInit, currentModel, tmpModel } =
    projectsState
  const { init } = authState

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [modelUsage, setModelUsage] = useState<
    ProjectModelUsageInterface | null | undefined
  >(null)

  // Effect
  useEffect(() => {
    window.addEventListener('resize', setContentsBuilderLayout)
  }, [])

  useEffect(() => {
    if (currentProject && modelListInit) {
      setTimeout(() => {
        setContentsBuilderLayout()
      })
    }
  }, [currentProject, modelListInit])

  useEffect(() => {
    if (currentModel && currentProject && currentProject.usage) {
      setModelUsage(
        currentProject.usage.modelList.find((m) => m.id === currentModel.id)
      )
    }
  }, [currentModel, currentProject])

  /**
   * 본문영역 높이 조절
   */
  const setContentsBuilderLayout = () => {
    const body = document.getElementById('contentsBuilder-body')
    const header = document.getElementById('contentsBuilder-header')

    if (body && header) {
      body.style.height = window.innerHeight - (header.clientHeight - 1) + 'px'
    }
  }

  /**
   * 모델 액션 메뉴
   * @param model
   * @returns
   */
  const modelMenusItem = (model) => {
    return [
      {
        key: 'edit',
        label: t('editModel'),
        onClick: () => {
          onModelEdit(model)
        },
      },
      {
        key: 'duplicate',
        label: t('duplicateModel'),
        onClick: () => {
          onModelDuplicate(model)
        },
      },
      {
        key: 'delete',
        label: t('deleteModel'),
        onClick: () => {
          onModelDelete(model)
        },
      },
    ]
  }

  /**
   * 모델 액션 툴
   * @param model
   */
  const modelMenus = (model) => (
    <Menu className={'w-48'} items={modelMenusItem(model)} />
  )

  /**
   * 컴포넌트 정렬
   * @param oldIndex
   * @param newIndex
   */
  const onSortEnd = (result) => {
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    const components = JSON.parse(JSON.stringify(tmpModel?.componentList))
    const [removed] = components.splice(startIndex, 1)
    components.splice(endIndex, 0, removed)

    const updatedModel = tmpModel
    if (updatedModel) {
      updatedModel.componentList = components
      // dispatch(setCurrentModel(updatedModel))

      dispatch(setTmpModel(updatedModel))
    }
  }

  /**
   * 모델 수정폼 열기
   * @param model
   */
  const onModelEdit = (model) => {
    dispatch(setModelFormModal(true, model))
  }

  /**
   * 모델 삭제
   * @param model
   */
  const onModelDelete = (model) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteModelTitle', {
        model: currentProject
          ? model.languageMap[currentProject?.defaultLang]
          : '',
      }),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteModelDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteModel(currentProject?.uid, model.id)
            .then((res) => {
              dispatch(
                getModels(currentProject?.uid, model.id === currentModel?.id)
              )
              dispatch(getProjectUsage(currentProject?.uid as string))
              message.success(t('deleteSuccess'))
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 모델 복제
   * @param model
   */
  const onModelDuplicate = (model) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDuplicateModelTitle', {
        model: currentProject
          ? model.languageMap[currentProject?.defaultLang]
          : '',
      }),
      icon: <CopyOutlined />,
      content: t('confirmDuplicateModelDesc'),
      okText: t('duplicate'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          if (!currentProject || !currentModel) return

          // 언어별 값 선택
          const languageMap = {}
          currentProject.languageList.forEach((lang) => {
            languageMap[lang] = currentModel.languageMap[lang] + '_복제'
          })

          const req = {
            languageMap,
            devKey: uuidv4(),
            componentList: JSON.parse(
              JSON.stringify(currentModel.componentList)
            ),
          }

          createModel(currentProject?.uid, req)
            .then((res) => {
              getModelData(currentProject?.uid, res.data.data).then((res) => {
                const newModel = res.data.data

                // 사용량 갱신
                getProjectUsageData(currentProject?.uid)
                  .then((res) => {
                    const updatedProject = currentProject
                    updatedProject.usage = res.data

                    dispatch(setCurrentProject(updatedProject))
                    dispatch(getModels(currentProject?.uid, currentModel.id))
                    resolve(res)
                  })
                  .catch((e) => {
                    message.error(e.response.data.error)
                  })
              })
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 저장
   * @returns
   */
  const saveModel = async () => {
    if (!tmpModel || loading) return false

    setLoading(true)

    const req = {
      languageMap: tmpModel.languageMap,
      devKey: tmpModel.devKey,
      componentList: setComponentsRequestBody(tmpModel.componentList),
    }

    const tmpFlattedComponents = JSON.parse(
      JSON.stringify(tmpModel.flattenComponentList)
    )

    await updateModel(currentProject?.uid, tmpModel.id, req)
      .then(async (res) => {
        await dispatch(getModels(currentProject?.uid, currentModel?.id))
        message.success(t('saveSuccess'))
      })
      .catch((e) => {
        message.error(e.response.data.error)
      })
      .then(() => {
        setLoading(false)
      })
  }

  /**
   * 컨텐츠 편집 이동 변경
   * @param model
   * @returns
   */
  const goContentsByModel = (model) => {
    if (!model) return

    if (
      tmpModel &&
      JSON.stringify(currentModel) !== JSON.stringify(tmpModel) &&
      !confirm(t('confirmNotSaveRecently'))
    ) {
      return false
    }

    dispatch(setTmpModel(currentModel))
    dispatch(resetContentsList())

    navigate(
      `/projects/${currentProject?.uid}/contents/${
        model.id
      }?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
        model.id
      )}&q=`
    )
  }

  return (
    <LayoutProjects>
      {currentProject && currentModel && (
        <Helmet>
          <title>
            {currentModel.languageMap.KO} · {t('contentsBuilder')} ·{' '}
            {currentProject.name} · {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject && modelListInit ? (
        tmpModel ? (
          <div className={'flex flex-col h-screen overflow-hidden'}>
            {/* Contents Builder header: 시작 */}
            <div
              id="contentsBuilder-header"
              className="grow-0 flex-none px-6 py-4 border-b border-gray-300 flex justify-between items-center">
              <div>
                <h1
                  className={
                    'mb-0 text-lg leading-7 flex items-center space-x-4'
                  }>
                  <span>
                    {tmpModel?.languageMap[currentProject.defaultLang]}
                  </span>
                  <span className="text-xs font-normal text-gray-600">
                    {tmpModel?.devKey}
                  </span>
                  {currentProject.role === 'ADMIN' ? (
                    <span>
                      <Dropdown
                        overlay={modelMenus(tmpModel)}
                        trigger={['click']}>
                        <Button
                          type={'text'}
                          size="small"
                          icon={<EllipsisOutlined />}
                          title={t('more')}
                        />
                      </Dropdown>
                    </span>
                  ) : (
                    <></>
                  )}
                </h1>
              </div>
              {currentProject.role === 'ADMIN' ? (
                <div className={'flex items-center space-x-3'}>
                  {currentProject.usage?.type !== 'UNLIMITED' &&
                  modelUsage?.component &&
                  currentModel?.flattenComponentList ? (
                    <Tooltip
                      title={t('noComponentsByModelDesc')}
                      className="cursor-help">
                      <span
                        className="font-normal flex space-x-1 items-center"
                        style={{
                          color:
                            currentModel.flattenComponentList.length >=
                            modelUsage?.component.limit
                              ? LAYOUT_WARNING_COLOR
                              : LAYOUT_OKAY_COLOR,
                        }}>
                        <span>
                          {currentModel?.flattenComponentList.length} /{' '}
                          {modelUsage?.component.limit}
                        </span>
                        <InfoCircleOutlined className="mt-px" />
                      </span>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  <Button
                    type={'primary'}
                    disabled={
                      loading ||
                      JSON.stringify(currentModel) === JSON.stringify(tmpModel)
                    }
                    className="relative"
                    loading={loading}
                    icon={<SaveOutlined />}
                    onClick={() => saveModel()}
                    title={t('save')}>
                    {t('save')}
                    {loading ||
                    JSON.stringify(currentModel) ===
                      JSON.stringify(tmpModel) ? (
                      ''
                    ) : (
                      <span
                        className={`animate-ping w-4 h-4 absolute -right-2 -top-2 rounded-full bg-blue-400 opacity-75`}></span>
                    )}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* Contents Builder header: 끝 */}
            {/* Contents Builder body: 시작 */}
            <div
              id="contentsBuilder-body"
              className="grow h-full relative"
              style={{
                paddingRight: LAYOUT_COMPONENT_TYPE_WIDTH,
              }}>
              <div className="h-full overflow-y-auto pb-10">
                <div className="py-6 px-20">
                  <DragDropContext onDragEnd={onSortEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          className="space-y-2"
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          {tmpModel?.componentList?.map((component, index) => (
                            <Draggable
                              disabled={true}
                              key={component.id}
                              draggableId={(component.id as number).toString()}
                              index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}>
                                  <div>
                                    <ComponentsItem
                                      dragHandler={provided.dragHandleProps}
                                      component={component}
                                      customClassName="bg-white shadow rounded"
                                      disabled={
                                        !!(
                                          tmpModel?.flattenComponentList &&
                                          modelUsage &&
                                          tmpModel.flattenComponentList
                                            .length >=
                                            modelUsage?.component.limit
                                        )
                                      }
                                    />
                                    {provided.placeholder}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {currentProject?.role === 'ADMIN' ? (
                            <Draggable
                              key={999999}
                              draggableId={'999999'}
                              isDragDisabled={true}
                              disableInteractiveElementBlocking={true}
                              index={999999}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <div className={`mt-2`}>
                                    <ComponentsAdd
                                      disabled={
                                        !!(
                                          tmpModel?.flattenComponentList &&
                                          modelUsage &&
                                          tmpModel.flattenComponentList
                                            .length >=
                                            modelUsage?.component.limit
                                        )
                                      }
                                    />
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          ) : (
                            <></>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {tmpModel?.componentList && tmpModel?.componentList.length ? (
                    <div className="flex justify-end py-6">
                      <Button
                        onClick={() => goContentsByModel(tmpModel)}
                        title={t('goEditContents')}
                        icon={<ArrowLeftOutlined />}>
                        {t('goEditContents')}
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {currentProject?.role === 'ADMIN' ? (
                <>
                  <ComponentsFormModal />
                  <CategoriesFormModal />
                </>
              ) : (
                <></>
              )}
            </div>
            {/* Contents Builder body: 끝 */}
          </div>
        ) : (
          <div
            className={'h-screen flex justify-center items-center text-center'}>
            <div>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              <p className={'leading-6'}>{t('startCreateModel')}</p>
              {currentProject?.role === 'ADMIN' ? (
                <div className={'flex justify-center'}>
                  <Button
                    type={'primary'}
                    htmlType={'button'}
                    onClick={() => dispatch(setModelFormModal(true))}>
                    {t('makeModel')}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
      {currentProject?.role === 'ADMIN' ? <ModelsFormModal /> : <></>}
    </LayoutProjects>
  )
}

export default ProjectsContentsBuilder
