import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PlusCircleOutlined } from '@ant-design/icons'
import { ComponentInterface } from '@/types'
import { setComponentFormModal } from '@/states/actions/modals.actions'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'

interface ComponentsItemProps {
  parentComponent?: ComponentInterface
  disabled: boolean
}

export const ComponentsAdd = ({
  parentComponent,
  disabled = false,
}: ComponentsItemProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState

  return currentProject ? (
    <div
      className={`bg-gray-100 hover:bg-gray-200 flex items-center space-x-2 shadow rounded p-3 text-gray-600 ${
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      }`}
      onClick={() =>
        dispatch(
          setComponentFormModal(disabled ? false : true, null, parentComponent)
        )
      }>
      <PlusCircleOutlined />
      <div className="text-xs leading-6 font-bold">
        {(parentComponent
          ? parentComponent.languageMap[currentProject?.defaultLang] + ' '
          : '') + t('addComponent')}
      </div>
    </div>
  ) : (
    <></>
  )
}
